import React from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import ChannelHelper from '../../../helpers/ChannelHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapWhite} from '../../../styles/partnertap_theme';
import PagingBase from '../../../ui/lists/PagingBase';
import Loading from '../../../ui/Loading';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import CoSellActionListMetadataLive from './co_sell_components/CoSellActionListMetadataLive';
import DownloadHelper from '../../../helpers/DownloadHelper';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import CoSellActionListWorkflowSelector from './co_sell_components/CoSellActionListWorkflowSelector';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import {Routes} from '../../../globals/Routes';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {ACTION_LIST_ARCHIVED_REPORT_TYPE, ACTION_LIST_REPORT_TYPE, canUserEditActionLists} from './CoSellEngineHelperLive';
import ActionButton from '../../../ui/buttons/ActionButton';
import {ChecklistRtl, Download, UploadFile} from '@mui/icons-material';
import MessageBoxAlert from '../../../ui/messages/MessageBoxAlert';
import FeatureHelper, {FEATURE_CHANNEL_DOWNLOAD_REPORTS, FEATURE_COSELL_DOWNLOAD_ACTION_LISTS_AND_REPORTS} from '../../../helpers/FeatureHelper';
import Dialog from '../../../ui/Dialog';
import FilterHelper from '../../../helpers/FilterHelper';
import CoSellActionListRecordActions from './co_sell_components/CoSellActionListRecordActions';
import {ACCOUNTS_ASSET, OPPORTUNITIES_ASSET} from '../../../globals/Enums';
import PersistenceHelper from '../../../helpers/PersistenceHelper';

const ACTION_DELETE = 'delete';
const ACTION_RESTORE = 'restore';

class CoSellActionListPageLive extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = true;
		this.state.showRemoved = this.props.match.params.reportType !== ACTION_LIST_REPORT_TYPE;
		this.state.loadingMetadata = true;
		this.state.downloading = false;
		this.state.filterSelectorFunction = CoSellEngineEndpoints.getCoSellAnalyticsFilters;
		this.state.filterSelectorMounted = false;

		this.getActionList = this.getActionList.bind(this);
		this.updateSelectAllCheckbox = this.updateSelectAllCheckbox.bind(this);
		this.updateAllRows = this.updateAllRows.bind(this);
		this.updateRow = this.updateRow.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
		this.applyActionToSelectedRecords = this.applyActionToSelectedRecords.bind(this);
	}

	componentDidMount() {
		this.getActionList();
	}

	getActionList() {
		Promise.all([
			CoSellEngineEndpoints.getCoSellActionListMetadata(this.cosellActionListMetadataPublicId),
			CoSellEngineEndpoints.getActiveActionListWorkflow(this.cosellActionListMetadataPublicId)
		])
		.then((results) => {
			if (this.unmounted) return;
			const actionListMetadata = results[0].payload;
			const activeWorkflow = results[1].payload instanceof Array ? null : results[1].payload;
			this.setState({actionListMetadata: actionListMetadata, activeWorkflow: activeWorkflow, loadingMetadata: false});
			this.fetchColumns();
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCoSellActionListMetadata', error);
			this.setState({loadingMetadata: false, error: true});
		});
	}

	get cosellActionListMetadataPublicId() {
		return this.props.match.params.actionListId;
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	get filterSelectorArgs() {
		return {
			coSellActionListMetadataPublicId: this.cosellActionListMetadataPublicId,
			coSellReportType: this.reportType,
		};
	}

	get isActionListUsed() {
		return Boolean(this.state.activeWorkflow);
	}

	get canUserEditActionLists() {
		let {actionListMetadata} = this.state;
		const {authState} = this.props;
		return canUserEditActionLists(actionListMetadata, authState);
	}

	get storageKeyBase() {
		return `co_sell_action_list_${this.reportType}_${this.props.match.params.actionListId}`;
	}

	get title() {
		let {showRemoved} = this.state;
		let options = [{key: ACTION_LIST_REPORT_TYPE, label: 'Action List Records'}, {key: ACTION_LIST_ARCHIVED_REPORT_TYPE, label: 'Removed Records'}];
		return (
			<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 340, height: 55}}>
				{this.actionRowCount ?
					this.actionButtons :
					<DropdownMenu options={options}
								  selectedOption={options[showRemoved ? 1 : 0]}
								  onSelect={(option) =>
									  EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_LIVE.PATH(this.cosellActionListMetadataPublicId, option.key))
								  }/>}
			</div>);
	}

	get actionButtons() {
		let countSelectedForRemove = 0;
		let countSelectedForRestore = 0;
		let {rowData, showRemoved} = this.state;
		rowData.forEach((row) => {
			if (row.applyAction) {
				if (row.is_deleted) {
					countSelectedForRestore++;
				}
				else {
					countSelectedForRemove++;
				}
			}
		});
		return (
			<div style={{display: 'flex', alignItems: 'center', gap: 10, paddingLeft: 10}}>
				<div style={{fontSize: 16}}>
					{countSelectedForRemove || countSelectedForRestore} selected
				</div>
				{showRemoved ?
					<PrimaryButton label={'RESTORE'}
								   disabled={!countSelectedForRestore}
								   onClick={() => this.applyActionToSelectedRecords(ACTION_RESTORE)}/>
					:
					<PrimaryButton label={'REMOVE'}
								   disabled={!countSelectedForRemove}
								   onClick={() => this.applyActionToSelectedRecords(ACTION_DELETE)}/>}
				<SecondaryButton label={'CANCEL'} onClick={() => this.updateAllRows(false)}/>
			</div>
		);
	}

	get icon() {
		return null;
	}

	fetchColumns() {
		let {actionListMetadata} = this.state;
		CoSellEngineEndpoints.getCoSellAnalyticsColumns(this.cosellActionListMetadataPublicId, this.reportType)
		.then((result) => {
			if (this.unmounted) return;

			const columns = [
				{
					title: 'Actions',
					key: 'noteActions',
					sortDisabled: true,
					renderFunction: (columnValue, rowData) => (
						<CoSellActionListRecordActions
							refreshTable={() => this.setState({loading: true})}
							actionListMetadataPublicId={this.cosellActionListMetadataPublicId}
							displayPartnerDropdown={actionListMetadata.isFromEcoReport}
							actionListPartnerOrgs={actionListMetadata.partnerOrgs}
							assetObjectId={rowData.crmopportunityid || rowData.crmaccountid}
							assetType={rowData.crmopportunityid ? OPPORTUNITIES_ASSET : ACCOUNTS_ASSET}
						/>
					)
				},
				...result.payload.columns
			];

			this.initColumnData(columns);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getGlobalReportCompanyAssetColumns', error);
		});
	}

	get additionalToolbarButtons() {
		return [
			<ActionButton icon={<ChecklistRtl/>}
						  toolTip={'Manually modify action list'}
						  disabled={this.isActionListUsed || !this.canUserEditActionLists}
						  key={"manually_modify_action_list"}
						  onAction={() => {
							  const isInManualEdit = !!this.state.columnData.find(column => column.key === ACTIONS);
							  const columnData = [...this.state.columnData];
							  if (isInManualEdit) {
								  this.updateAllRows(false);
								  columnData.shift();
							  }
							  else {
								  columnData.unshift({
									  key: ACTIONS,
									  title: <CheckboxButton style={{color: partnerTapWhite}} onChange={(event) => this.updateAllRows(event.target.checked)}/>,
									  renderFunction: (columnValue, rowData) =>
										  <div style={{display: 'flex', justifyContent: 'center'}}>
											  <CheckboxButton checked={rowData.applyAction} onClick={() => this.updateRow(rowData)}/>
										  </div>,
									  active: true
								  });
							  }
							  this.setState({columnData: columnData});
						  }}/>,
			<ActionButton icon={<UploadFile/>}
						  toolTip={'Bulk modify action list via CSV upload\n'}
						  disabled={this.isActionListUsed || !this.canUserEditActionLists}
						  key={"bulk_modify"}
						  onAction={() => {
							  EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_EDIT_LIVE.PATH(this.props.match.params.actionListId));
						  }}/>,
			<ActionButton key={'download_button_new'}
						  toolTip={'Create Downloadable CSV'}
						  onAction={this.handleDownload}
						  inProgress={this.state.downloading}
						  icon={<Download/>}/>
		];
	}

	updateSelectAllCheckbox(checked) {
		this.columnData[0].title =
			<CheckboxButton checked={checked} style={{color: partnerTapWhite}} onChange={(event) => this.updateAllRows(event.target.checked)}/>;
	}

	updateAllRows(checked) {
		let {rowData} = this.state;
		rowData.forEach((row) => row.applyAction = checked);
		this.updateSelectAllCheckbox(checked);
		this.setState(prevState => ({
			rowData: prevState.rowData.map(item =>
				({...item, applyAction: checked})
			)
		}));
	}

	updateRow(row) {
		this.setState(prevState => ({
			rowData: prevState.rowData.map(item =>
				item === row ? {...item, applyAction: !item.applyAction} : item
			)
		}));
	}

	get actionRowCount() {
		let actionCount = 0;
		let {rowData} = this.state;
		rowData.forEach((row) => row.applyAction ? actionCount++ : null);
		return actionCount;
	}

	getActionRowPublicIds(actionToApply) {
		let {rowData} = this.state;
		let actionRowPublicIds = [];
		rowData.forEach((row) => {
			if (row.applyAction &&
				((actionToApply === ACTION_DELETE && !row.is_deleted) ||
				 (actionToApply === ACTION_RESTORE && row.is_deleted))) {
				actionRowPublicIds.push(row.public_id);
			}
		});
		return actionRowPublicIds;
	}

	applyActionToSelectedRecords(actionToApply) {
		this.setState({saving: true});
		let actionRowPublicIds = this.getActionRowPublicIds(actionToApply);
		const isProcessingAllItems = actionRowPublicIds.length === this.state.rowData.length;
		const isLastPage = this.state.currentPage === this.state.pageCount;
		CoSellEngineEndpoints.updateCoSellActionListRecords(this.cosellActionListMetadataPublicId, actionToApply, actionRowPublicIds)
		.then((result) => {
			if (this.unmounted) return;
			this.updateSelectAllCheckbox(false);
			const columnData = [...this.state.columnData];
			this.updateAllRows(false);
			columnData.shift();
			if (isProcessingAllItems && isLastPage && this.state.pageCount > 1) {
				this.setState({
					loading: true,
					saving: false,
					columnData: columnData,
					currentPage: this.state.currentPage - 1
				});

				PersistenceHelper.setValue(this.pageStorageKey, this.state.currentPage - 1);
			}
			else {
				this.setState({
					loading: true,
					saving: false,
					columnData: columnData
				});
			}
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from applyActionToSelectedRecords', error);
		});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		let {showRemoved} = this.state;
		CoSellEngineEndpoints.getCoSellAnalyticsRecords(this.cosellActionListMetadataPublicId, this.reportType, params, showRemoved)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
					// ChannelHelper.processRowData(result.payload, this.reportType);
				}
				result.payload.forEach((row) => row.applyAction = false);
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			let {showRemoved} = this.state;
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						{showRemoved ? 'No records have been removed from this action list' : 'All records have been removed from this action list'}
					</div>
					{showRemoved ?
						<PrimaryButton label={'SHOW ACTION LIST RECORDS'} onClick={() =>
							EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_LIVE.PATH(this.cosellActionListMetadataPublicId, ACTION_LIST_REPORT_TYPE))
						}/> :
						<PrimaryButton label={'SHOW REMOVED RECORDS'} onClick={() =>
							EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_LIVE.PATH(this.cosellActionListMetadataPublicId, ACTION_LIST_ARCHIVED_REPORT_TYPE))

						}/>}
				</div>
			);
		}
		return super.noDataMessage;
	}

	get hasGeneralNoDataMessage() {
		return this.state.loading || Boolean(this.state.searchValue) || FilterHelper.getAddedFilters(this.filterSelectorConfig).length;
	}

	showUpsell() {
		this.setState({showUpsellDialog: true});
	}

	isReportDownloadEnabled() {
		const isChannelDownloadReportsEnabled = FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DOWNLOAD_REPORTS);
		const isCoSellDownloadReportsEnabled = FeatureHelper.isFeatureEnabled(FEATURE_COSELL_DOWNLOAD_ACTION_LISTS_AND_REPORTS);
		return isChannelDownloadReportsEnabled || isCoSellDownloadReportsEnabled;
	}

	handleDownload() {
		if (!this.isReportDownloadEnabled()) {
			this.showUpsell();
			return;
		}
		let params = this.processParameters(true);
		this.setState({downloading: true});
		CoSellEngineEndpoints.getCoSellAnalyticsDownload(this.cosellActionListMetadataPublicId, this.reportType, params.filters, params.search,
			DownloadHelper.getFields(this.state.columnData.map(column =>
				column.key === 'public_id'
					? {...column, active: !column.activeInverted}
					: column
			)))
		.then(result => {
			this.setState({downloading: false});
		})
		.catch(error => {
			this.setState({downloading: false});
			this.processError(error);
		});
	}

	render() {
		let {actionListMetadata, activeWorkflow, saving, loadingMetadata, error} = this.state;
		if (loadingMetadata) return <Loading>Loading Action List...</Loading>;
		if (error) return <MessageBoxAlert>An unexpected error occurred.</MessageBoxAlert>;
		const title = 'Upgrade Required';
		const message = (<>Downloading reports is a paid feature. Please reach out to <a href={'mailto:sales@partnertap.com'}>sales@partnertap.com</a> to
			upgrade.</>);
		return (
			<div>
				{this.state.showUpsellDialog &&
				 <Dialog title={title}
						 message={message}
						 yesAction={() => this.setState({showUpsellDialog: false})}
				 />}
				{saving &&
				 <ScrimMessage message={'Saving...'}/>}
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
					<CoSellActionListMetadataLive actionListMetadataPublicId={this.cosellActionListMetadataPublicId} activeWorkflow={activeWorkflow}/>
					<CoSellActionListWorkflowSelector actionListMetadata={actionListMetadata}/>
				</div>
				{super.render()}
			</div>
		);
	}
}

CoSellActionListPageLive.propTypes = {
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(CoSellActionListPageLive));
