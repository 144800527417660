import React, {Component} from 'react';
import DetailHeader from '../shared/DetailHeader';
import {GroupAdd, GroupRemove, VerifiedUser, Warning} from '@mui/icons-material';
import LinkButton from '../../../ui/buttons/LinkButton';
import EnvHelper from '../../../helpers/EnvHelper';
import {Routes} from '../../../globals/Routes';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import {partnerTapPrimary, partnerTapWarn, partnerTapWhite} from '../../../styles/partnertap_theme';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';
import PropTypes from 'prop-types';
import TextInputBox from '../../../ui/TextInputBox';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import Loading from '../../../ui/Loading';
import Dialog from '../../../ui/Dialog';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as RestAdminCenterEndPoints from '../../../endpoints/RestAdminCenterEndPoints';
import {TYPES} from '../admin_center/CustomColumnEditorHome';
import LabeledPopOverList from '../../../ui/LabeledPopOverList';

const tableDataCellStyle = {padding: 10, fontSize: 14};

const PERSON_NAME_LABEL = 'Person Name';
const PERSON_NAME_KEY = 'name';
const PERSON_NAME_ENUM = 'NAME';

const PERSON_EMAIL_LABEL = 'Person Email';
const PERSON_EMAIL_KEY = 'email';
const PERSON_EMAIL_ENUM = 'EMAIL';

const JOB_TITLE_LABEL = 'Job Title';
const JOB_TITLE_KEY = 'title';
const JOB_TITLE_ENUM = 'TITLE';

const PHONE_NUMBER_LABEL = 'Phone Number';
const PHONE_NUMBER_KEY = 'phone';
const PHONE_NUMBER_ENUM = 'PHONE';

const STRING_LABEL = 'String';
const STRING_KEY = 'string';
const STRING_ENUM = 'STRING';

const CURRENCY_LABEL = 'Currency';
const CURRENCY_KEY = 'currency';
const CURRENCY_ENUM = 'CURRENCY';


const BOOLEAN_LABEL = 'Boolean';
const BOOLEAN_KEY = 'boolean';
const BOOLEAN_ENUM = 'BOOLEAN';

const NUMBER_LABEL = 'Number';
const NUMBER_KEY = 'number';
const NUMBER_ENUM = 'NUMBER';

const DATE_LABEL = 'Date';
const DATE_KEY = 'date';
const DATE_ENUM = 'DATE';

class CustomColumnsView extends Component {
	static PERSON_NAME_OPTION = {label: PERSON_NAME_LABEL, key: PERSON_NAME_KEY, enumValue: PERSON_NAME_ENUM};
	static PERSON_EMAIL_OPTION = {label: PERSON_EMAIL_LABEL, key: PERSON_EMAIL_KEY, enumValue: PERSON_EMAIL_ENUM};
	static JOB_TITLE_OPTION = {label: JOB_TITLE_LABEL, key: JOB_TITLE_KEY, enumValue: JOB_TITLE_ENUM};
	static PHONE_NUMBER_OPTION = {label: PHONE_NUMBER_LABEL, key: PHONE_NUMBER_KEY, enumValue: PHONE_NUMBER_ENUM};
	static STRING_OPTION = {label: STRING_LABEL, key: STRING_KEY, enumValue: STRING_ENUM};
	static CURRENCY = {label: CURRENCY_LABEL, key: CURRENCY_KEY, enumValue: CURRENCY_ENUM};
	static BOOLEAN = {label: BOOLEAN_LABEL, key: BOOLEAN_KEY, enumValue: BOOLEAN_ENUM};
	static NUMBER = {label: NUMBER_LABEL, key: NUMBER_KEY, enumValue: NUMBER_ENUM};
	static DATE = {label: DATE_LABEL, key: DATE_KEY, enumValue: DATE_ENUM};
	static TYPE_TO_NAME = new Map([
		[PERSON_NAME_KEY, this.PERSON_NAME_OPTION],
		[PERSON_EMAIL_KEY, this.PERSON_EMAIL_OPTION],
		[JOB_TITLE_KEY, this.JOB_TITLE_OPTION],
		[PHONE_NUMBER_KEY, this.PHONE_NUMBER_OPTION],
		[STRING_KEY, this.STRING_OPTION],
		[CURRENCY_KEY, this.CURRENCY],
		[BOOLEAN_KEY, this.BOOLEAN],
		[NUMBER_KEY, this.NUMBER],
		[DATE_KEY, this.DATE]
	]);
	static CUSTOM_COLUMN_PARAMS_DETAILS = [
		{columnParameter: 'Column Display Name', detail: 'Shows column name all users will see.'},
		{
			columnParameter: 'Column is Hidden',
			detail: 'Shows whether column is visible or not. Deactivating columns removes them from view across all reports. Ideal for use on columns that are no longer in use.'
		},
		{
			columnParameter: 'Column is Unique ID',
			detail: 'Shows columns that are available as the unique ID reference for data enrichments.'
		},
		{
			columnParameter: 'Column is PII',
			detail: 'Shows which columns contain Personally Identifiable Information (PII) and enables data in those columns to be masked when shared with a partner. Note: the Consent to Share feature must be enabled.'
		},
		{
			columnParameter: 'Column Category',
			detail: 'Shows what type of data is in those columns. Tagging columns here as contact data (i.e. name, email, title, etc) makes them available for co-sell workflows.'
		},
		{
			columnParameter: 'Contact Groups',
			detail: 'Shows the columns that belong to a specific contact group. Contact groups are used in co-sell workflows.'
		},
		{columnParameter: 'Column Source', detail: 'Shows the source of the column - CRM integration or CSV upload.'}
	];
	static COLUMN_CATEGORY_OPTIONS = [
		this.PERSON_NAME_OPTION,
		this.PERSON_EMAIL_OPTION,
		this.JOB_TITLE_OPTION,
		this.PHONE_NUMBER_OPTION,
		this.STRING_OPTION
	];
	static COLUMN_DISABLED_CATEGORY_OPTIONS = [
		this.CURRENCY,
		this.DATE,
		this.BOOLEAN,
		this.NUMBER
	];

	constructor(props, context) {
		super(props, context);
		this.state = {
			customMetadataAsset: null,
			loading: false,
			isEdit: false,
			title: 'Custom Columns',
			customColumns: [],
			customColumnBulkEditMap: new Map(),
			showEditInfoDialog: false,
			updateError: null,
			showAllColumns: true, // New flag to control column visibility
			parentPath: Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.CUSTOM_COLUMN_EDITOR.ROUTE,
			showContactAddDialog: false,
			contactAddInProgress: false,
			contactRemoveInProgress: false,
			fetchLatestColumns: false,
			showContactRemoveDialog: false,
			showColumnHiddenInfoDialog: false,
			isHiddenFieldUpdated: false,
			isHiddenFieldUpdatedToTrue: false,
			disableGroupRemoveIcon: false,
			contactGroupMap: new Map(),
			newContactGroupValidation: {
				isContactGroupNameMissing: false,
				isContactNameMissing: false,
				isContactEmailMissing: false
			},
			contactGroup: {
				contactGroupName: null,
				contactName: null,
				contactEmail: null,
				contactTitle: null,
				contactPhoneNumber: null,
				assetType: null
			},
			contactsTobeDeleted: new Set()

		};
	}

	componentDidMount() {
		const {customMetadataAsset, isEdit} = this.props.match.params;
		this.setState({loading: true});
		this.getOrgCustomMetadata(customMetadataAsset, isEdit);
	}

	getOrgCustomMetadata(customMetadataAsset, isEdit) {
		RestAdminCenterEndPoints.getCustomMetadata()
		.then(result => {
			let customColumnItems = [];
			let title = 'Custom Columns';
			customColumnItems = result.payload[customMetadataAsset];
			customMetadataAsset === TYPES ? title = title + ' (Accounts)' : title = title + ' (Opps)';
			const contactGroupMap = new Map(customColumnItems.filter(obj => obj.contactGroupName != null).map(obj => [
				obj.contactGroupName,
				obj.contactGroup
			]));

			if (isEdit === 'edit') {
				title = title + ' - Edit';
				this.setState({parentPath: `${Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.CUSTOM_COLUMN_EDITOR.ROUTE}/${customMetadataAsset}`});
			}
			this.setState({
				customMetadataAsset: customMetadataAsset,
				customColumns: customColumnItems,
				fetchLatestColumns: false,
				loading: false,
				isEdit: (isEdit === 'edit'),
				title: title,
				contactGroupMap: contactGroupMap,
				editPath: `${Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.CUSTOM_COLUMN_EDITOR.ROUTE}/${customMetadataAsset}/edit`
			});
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (!prevState.fetchLatestColumns && this.state.fetchLatestColumns) {
			this.getOrgCustomMetadata(this.state.customMetadataAsset, false);
		}
	}

	isColumnCategoryDisabled = (column) => {
		const currentColumnCategory = CustomColumnsView.TYPE_TO_NAME.get(column.type);
		return CustomColumnsView.COLUMN_DISABLED_CATEGORY_OPTIONS.includes(currentColumnCategory);
	};

	getColumnCategoryLabelName = (column) => {
		return CustomColumnsView.TYPE_TO_NAME.get(column.type)?.label;
	};

	addOrUpdatedCustomColumnInState(customColumn) {
		this.state.customColumnBulkEditMap.set(customColumn.name, {...customColumn});
		const updatedMap = new Map([...this.state.customColumnBulkEditMap]);
		this.setState({
			customColumnBulkEditMap: updatedMap
		});
	}

	removeCustomColumnFromBulkEditMapInState(customColumn) {
		this.setState({
			customColumnBulkEditMap: new Map([...this.state.customColumnBulkEditMap].filter(([k, v]) => k !== customColumn.name))
		});
	}

	discardEdit() {
		this.setState({
			customColumnBulkEditMap: new Map()
		});
		EnvHelper.push(this.state.parentPath);
	}

	validateAndConfirmSave() {

		if (this.state.isHiddenFieldUpdated) {
			this.setState({showColumnHiddenInfoDialog: true});
			return;
		}

		this.saveEdit();
	}


	saveEdit() {
		let payload = {types: [], oppFields: []};
		payload = {
			...payload,
			[this.state.customMetadataAsset]: [...this.state.customColumnBulkEditMap.values()].map(column => ({
				...column,
				type: (CustomColumnsView.TYPE_TO_NAME.get(column.type)).enumValue
			}))
		};
		RestAdminCenterEndPoints.updateCustomMetadata(payload)
		.then(() => {
			if (this.unmounted) return;
			EnvHelper.push(this.state.parentPath);
		})
		.catch((error) => {
			this.setState({isEdit: false, updateError: 'Error occurred while updating custom metadata of org'});
			EnvHelper.serverError('Error from onUpload', error);
			EnvHelper.push(this.state.parentPath);
		});
	}

	async onContactAdd() {
		let {contactGroup} = this.state;
		this.setState({contactAddInProgress: true});
		if (!contactGroup.contactGroupName || !contactGroup.contactName || !contactGroup.contactEmail) {
			this.setState(preState => ({
				...preState,
				newContactGroupValidation: {
					isContactGroupNameMissing: !contactGroup.contactGroupName,
					isContactNameMissing: !contactGroup.contactName,
					isContactEmailMissing: !contactGroup.contactEmail
				}
			}));
			this.setState({contactAddInProgress: false});
			return;
		}

		let payload = {...contactGroup, assetType: this.state.customMetadataAsset};
		await RestAdminCenterEndPoints.createContactGroup(payload)
		.then(() => {
			if (this.unmounted) return;
		})
		.catch((error) => {
			this.setState({updateError: 'Error occurred while creating contact group'});
			EnvHelper.serverError('Error from create contact group', error);
		});

		this.setState({
			showContactAddDialog: false,
			contactAddInProgress: false,
			fetchLatestColumns: !this.state.fetchLatestColumns,
			contactGroup: {},
			newContactGroupValidation: {}

		})

		;
	}

	async onContactRemove() {
		this.setState({contactRemoveInProgress: true});
		let {contactsTobeDeleted} = this.state;
		console.log('contactsTobeDeleted', contactsTobeDeleted);
		let payload = {
			contactGroupUUIDs: [...contactsTobeDeleted],
			assetType: this.state.customMetadataAsset
		};
		await RestAdminCenterEndPoints.deleteContactGroup(payload)
		.then(() => {
			if (this.unmounted) return;
		})
		.catch((error) => {
			this.setState({updateError: 'Error occurred while deleting contact group'});
			EnvHelper.serverError('Error from delete contact group', error);
		});
		this.setState({
			showContactRemoveDialog: false,
			contactRemoveInProgress: false,
			fetchLatestColumns: !this.state.fetchLatestColumns,
			contactsTobeDeleted: new Set()
		});
	}

	ifInputIsEmpty = (key) => {
		switch (key) {
			case 'contactName' :
				return this.state.newContactGroupValidation?.isContactNameMissing;
			case 'contactEmail' :
				return this.state.newContactGroupValidation?.isContactEmailMissing;
			default:
				return false;

		}
	};

	onItemSelected = (fieldName, fieldValue) => {
		this.setState(prevState => ({
			...prevState,
			contactGroup: {
				...prevState.contactGroup,
				[fieldName]: fieldValue
			}
		}));

	};

	renderContactAddDialog() {
		const {customColumns} = this.state;
		let infoMessage = 'To create a contact group, please select the corresponding contact columns and give the group a name. This contact group will become available as a recipient for workflows in the Co-Sell Engine.';

		const getOrgMetadataForUnmappedContactGroup = customColumns.filter(obj => obj.contactGroup === null || obj.contactGroup === undefined || obj.contactGroup === 'null');

		const contactNameMap = new Map(getOrgMetadataForUnmappedContactGroup.filter(obj => obj.type === 'name').map(obj => [obj.label, obj.name]));
		const contactEmailMap = new Map(getOrgMetadataForUnmappedContactGroup.filter(obj => obj.type === 'email').map(obj => [obj.label, obj.name]));
		const contactTitleMap = new Map(getOrgMetadataForUnmappedContactGroup.filter(obj => obj.type === 'title').map(obj => [obj.label, obj.name]));
		const contactPhoneNumberMap = new Map(getOrgMetadataForUnmappedContactGroup.filter(obj => obj.type === 'phone').map(obj => [obj.label, obj.name]));

		return (
			<Dialog
				title={'Create contact group'}
				message={
					<div>
						<div style={{minWidth: 500, maxWidth: 700, fontWeight: 'bold', fontSize: 16}}>
							<p>{infoMessage}</p>
						</div>
						<div>
							<div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
								<div style={{width: '35%'}}>Contact group name</div>
								<TextInputBox hintText={'Contact group name'}
											  errorText={this.state.newContactGroupValidation.isContactGroupNameMissing ? 'Contact group Name is required' : ''}
											  onChange={(value) => this.setState(prevState => ({
												  ...prevState,
												  contactGroup: {
													  ...prevState.contactGroup,
													  contactGroupName: value
												  }
											  }))}
											  maxWidth={400}/*Calculated as <65% of 700*/
											  minWidth={300}
											  maxChars={40 + (this.state.contactGroup.contactGroupName ? this.state.contactGroup.contactGroupName.length - this.state.contactGroup.contactGroupName.trim().length : 0)}/>
							</div>
							<LabeledPopOverList fieldName="contactName"
												fieldLabel="Account owner name"
												fieldValueMap={contactNameMap}
												onItemSelected={this.onItemSelected}
												isMandatoryField={true}
												onItemMissing={this.ifInputIsEmpty}/>
							<LabeledPopOverList fieldName="contactEmail"
												fieldLabel="Account owner email"
												fieldValueMap={contactEmailMap}
												isMandatoryField={true}
												onItemSelected={this.onItemSelected}
												onItemMissing={this.ifInputIsEmpty}/>
							<LabeledPopOverList fieldName="contactTitle"
												fieldLabel="Account owner title"
												fieldValueMap={contactTitleMap}
												onItemSelected={this.onItemSelected}/>
							<LabeledPopOverList fieldName="contactPhoneNumber"
												fieldLabel="Account owner phone number"
												fieldValueMap={contactPhoneNumberMap}
												onItemSelected={this.onItemSelected}/>
						</div>
					</div>}
				yesLabel={'SAVE'}
				yesAction={() => this.onContactAdd()}
				yesDisable={this.state.contactAddInProgress}
				noLabel={'CANCEL'}
				noAction={() => this.setState({showContactAddDialog: false, contactAddInProgress: false})}/>);

	}

	renderContactRemoveDialog() {
		const {contactGroupMap} = this.state;
		let infoMessage = 'Please select which contact group(s) you would like to remove. Removing the group makes the columns available for a new contact group';

		return <Dialog
			title={'Remove contact group'}
			message={
				<div>
					<div style={{minWidth: 500, maxWidth: 700, fontWeight: 'bold', fontSize: 16}}>
						<p>{infoMessage}</p>
					</div>
					<div>
						{[...contactGroupMap.entries()].map(([groupName, groupUUId]) => (
							<div style={{display: 'flex', alignItems: 'center'}} key={groupUUId}>
								<CheckboxButton
									disabled={false}
									checked={this.state.contactsTobeDeleted.has(groupUUId)}
									onClick={() => this.setState(prevState => {
										const newContactsTobeDeletedSet = new Set(prevState.contactsTobeDeleted);
										if (newContactsTobeDeletedSet.has(groupUUId)) {
											newContactsTobeDeletedSet.delete(groupUUId);
										}
										else {
											newContactsTobeDeletedSet.add(groupUUId);
										}
										return {contactsTobeDeleted: newContactsTobeDeletedSet};
									})}
								/>
									<div style={{display: 'flex', padding: 10}}>
										<div>{groupName}</div>
									</div>
								</div>
							))}
					</div>
				</div>}
			yesLabel={'REMOVE'}
			yesAction={() => this.onContactRemove()}
			yesDisable={this.state.contactRemoveInProgress || this.state.contactsTobeDeleted.size === 0}
			noLabel={'CANCEL'}
			noAction={() => this.setState({showContactRemoveDialog: false, contactsTobeDeleted: new Set()})}/>;
	}

	renderEditInfoDialog() {
		return <Dialog
			title={'Custom Column Editor'}
			message={
				<div>
					<table style={{border: '1px solid black', borderCollapse: 'collapse', fontSize: 14}}>
						{
							CustomColumnsView.CUSTOM_COLUMN_PARAMS_DETAILS.map(customColumnParamInfo => (
								<tr key={customColumnParamInfo.columnParameter}>
									<td style={{border: '1px solid black', padding: 3}} width={200}>{customColumnParamInfo.columnParameter}</td>
									<td style={{border: '1px solid black', padding: 3}}>{customColumnParamInfo.detail}</td>
								</tr>
							))
						}
					</table>
				</div>
			}
			onClose={() => this.setState({showEditInfoDialog: false})}/>;
	}

	renderColumnHiddenInfoDialog() {

		let {isHiddenFieldUpdatedToTrue} = this.state;

		let title = (isHiddenFieldUpdatedToTrue) ? 'Hiding Columns' : 'Unhiding Columns';
		let infoMessageForHiddenValuesUpdatedToTrue = <>
			<div style={{display: 'flex', gap: 10}}>
				<Warning style={{color: partnerTapWarn}}/>
				<div style={{marginBottom: 10}}> In addition to removing columns from visibility in your org reports, hiding columns effects the following:
				</div>
			</div>
			<div style={{marginTop: 20, marginBottom: 20}}>
				<ul style={{marginLeft: 30}}>
					<li style={{marginBottom: 10}}> If shared with Partners, they will no longer see it</li>
					<li style={{marginBottom: 10}}> The column can no longer be shared</li>
					<li style={{marginBottom: 10}}> The column will not be visible in downloaded reports</li>
					<li style={{marginBottom: 10}}> The column will not be visible if re-uploaded via CSV</li>
					<li style={{marginBottom: 10}}> If CRM connected, the column will not be visible but the data in the column will continue to update</li>
				</ul>
			</div>
			<div> Select CONTINUE to proceed with hiding columns.</div>
		</>;

		let infoMessageForHiddenValuesUpdatedToFalse = <>
			<div style={{display: 'flex', gap: 10}}>
				<Warning style={{color: partnerTapWarn}}/>
				<div style={{marginBottom: 10}}> In addition to making columns visible again in your org, unhiding columns will also:</div>
			</div>
			<div style={{marginTop: 20, marginBottom: 20}}>
				<ul style={{marginLeft: 30}}>
					<li style={{marginBottom: 10}}> Make the columns visible to partners previously shared with</li>
					<li style={{marginBottom: 10}}> Make the columns visible in share settings picklist (if previously sharable)</li>
					<li style={{marginBottom: 10}}> Make the columns visible in downloaded reports</li>
				</ul>
			</div>
			<div> Select CONTINUE to proceed with unhiding columns.</div>
		</>;

		let infoMessage = isHiddenFieldUpdatedToTrue ? infoMessageForHiddenValuesUpdatedToTrue : infoMessageForHiddenValuesUpdatedToFalse;

		return <Dialog
			title={title}
			message={
				<div>
					<div style={{minWidth: 500, maxWidth: 700, fontSize: 16}}>
						<p>{infoMessage}</p>
					</div>
				</div>}
			yesLabel={'CONTINUE'}
			yesAction={() => this.saveEdit()}
			noLabel={'CANCEL'}
			noAction={() => this.setState({showColumnHiddenInfoDialog: false})}/>;

	}

	renderColumnsView() {
		return <div style={{display: 'flex', flexDirection: 'column'}}>
			{
				this.state.isEdit ?
					this.renderEditViewHeader() :
					(<>
						<MessageBoxInfo>
							<div>
								Below are all the custom columns for your account data. It includes columns imported via CSV uploads -
								both base data and data enrichments - and CRM Integration.
							</div>
						</MessageBoxInfo>
						<div style={{display: 'flex', justifyContent: 'flex-end', gap: '10px', padding: '0px 20px 5px 0px'}}>
							<div>
								<SecondaryButton onClick={() => this.setState({showContactAddDialog: true})}
												 toolTip={'Add contact group'}
												 icon={<GroupAdd/>}
												 minWidth={40}

								/>
							</div>
							<div style={{padding: '10'}}>
								<SecondaryButton onClick={() => this.setState({showContactRemoveDialog: true})}
												 toolTip={'Remove contact group'}
												 icon={<GroupRemove/>}
												 minWidth={40}
												 disabled={this.state.contactGroupMap.size < 1}
								/>
							</div>
							<div>
								<PrimaryButton label={'Edit'} onClick={() => EnvHelper.push(this.state.editPath)}/>
							</div>
						</div>
					</>)
			}
			<div>
				{this.renderCustomColumnsTableView()}
			</div>

		</div>;
	}

	renderEditViewHeader() {
		const editDescriptionTextWithIcon = <div style={{display: 'flex', gap: 5}}>
			<MessageBoxInfo>
				<span>Learn more about custom column editable fields</span>
			</MessageBoxInfo>
		</div>;
		return <div style={{
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			paddingLeft: 20,
			paddingRight: 20
		}}>
			<div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
				<LinkButton label={editDescriptionTextWithIcon} onClick={() => this.setState({showEditInfoDialog: true})}/>
			</div>
			<div style={{display: 'flex', flexDirection: 'row', gap: 15, paddingTop: 5, paddingBottom: 5}}>
				<PrimaryButton label={'SAVE'} onClick={() => this.validateAndConfirmSave()}
							   disabled={this.state.customColumnBulkEditMap.size === 0}/>
				<SecondaryButton label={'Cancel'} onClick={() => this.discardEdit()}/>
			</div>
		</div>;
	}

	renderCustomColumnsTableView() {
		const {showAllColumns} = this.state;

		return <table style={{
			borderCollapse: 'collapse',
			borderRadius: 10,
			backgroundColor: partnerTapWhite,
			textAlign: 'left',
			margin: '0px 15px 15px 10px',
			width: 'calc(100% - 25px)'
		}}>
			<thead style={{position: 'sticky', top: 0, zIndex: 1, background: partnerTapPrimary, color: partnerTapWhite}}>
			{this.getHeadersRow()}
			</thead>
			<tbody>
			{
				this.state.customColumns.map((column, index) => {
					let updatedColumn = null;
					let hidden = column.hidden;
					let isUniqueId = column.isUniqueId;
					let isPiiSensitiveValue = column.isPiiSensitive;
					if (this.state.customColumnBulkEditMap && this.state.customColumnBulkEditMap.has(column.name)) {
						updatedColumn = this.state.customColumnBulkEditMap.get(column.name);
						if (updatedColumn) {
							isPiiSensitiveValue = updatedColumn.isPiiSensitive;
							hidden = Boolean(updatedColumn.hidden);
							isUniqueId = Boolean(updatedColumn.isUniqueId);
						}
					}
					return <tr style={{borderTop: '1px solid lightgrey'}} key={index}>
						<td key={'apiNameHeader'.concat(' - ', index)} style={{...tableDataCellStyle, width: '20%'}}>{column.name}</td>
						<td key={'columnDisplayName'.concat(' - ', index)}
							style={{...tableDataCellStyle, width: '15%'}}>{this.getDisplayNameElement(this.state.isEdit, column, updatedColumn)}</td>
						<td key={'isColumnUniqueId'.concat(' - ', index)}
							style={{...tableDataCellStyle, width: '15%'}}>{this.getBooleanField(this.state.isEdit, column, updatedColumn, isUniqueId,
							(column, updatedColumn, updatedValue) => this.updateColumnProperty(column, updatedColumn, 'isUniqueId', updatedValue))}
						</td>
						{showAllColumns && (
							<>
								<td key={'columnisHidden'.concat(' - ', index)}
									style={{...tableDataCellStyle, width: '12%'}}>{this.getBooleanField(this.state.isEdit, column, updatedColumn, hidden,
									(column, updatedColumn, updatedValue) => this.updateColumnProperty(column, updatedColumn, 'hidden', updatedValue))}
								</td>
							</>
						)}
						<td key={'IsColumnPii'.concat(' - ', index)}
							style={{...tableDataCellStyle, width: '13%'}}>{this.getBooleanField(this.state.isEdit, column, updatedColumn, isPiiSensitiveValue,
							(column, updatedColumn, updatedValue) => this.updateColumnProperty(column, updatedColumn, 'isPiiSensitive', updatedValue))}
						</td>
						<td key={'columnCategory'.concat(' - ', index)}
							style={{
								...tableDataCellStyle,
								width: showAllColumns ? '12%' : '20%'
							}}>{this.isColumnCategoryDisabled(column) ? this.getColumnCategoryLabelName(column) : this.getColumnCategoryField(this.state.isEdit, column, updatedColumn)}</td>
						<td key={'contactGroup'.concat('-', index)}
							style={{
								...tableDataCellStyle,
								width: showAllColumns ? '12%' : '20%'
							}}>{column.contactGroupName}</td>
						<td key={'columnSource'.concat(' - ', index)}
							style={{...tableDataCellStyle, width: showAllColumns ? '13%' : '15%'}}>{column.source}</td>
					</tr>;
				})
			}
			</tbody>
		</table>;
	}

	getHeadersRow() {
		const {showAllColumns} = this.state;

		return <tr>
			<th key={'apiNameHeader'} style={{borderTopLeftRadius: 10, fontSize: 15, padding: 10, width: '20%'}}>API Name</th>
			<th key={'columnDisplayName'} style={{fontSize: 15, padding: 10, width: '15%'}}>Column Display Name</th>
			<th key={'isColumnUniqueId'} style={{fontSize: 15, padding: 10, width: '10%'}}>Column Is Unique ID</th>
			{showAllColumns && (
				<>
					<th key={'columnIsHidden'} style={{fontSize: 15, padding: 10, width: '12%'}}>Column Is Hidden</th>
				</>
			)}
			<th key={'IsColumnPii'} style={{fontSize: 15, padding: 10, width: '10%'}}>Column Is PII</th>
			<th key={'columnCategory'} style={{fontSize: 15, padding: 10, width: '15%'}}>Column Category</th>
			<th key={'contactGroup'} style={{fontSize: 15, padding: 10, width: '12%'}}>Contact Group</th>
			<th key={'columnSource'} style={{borderTopRightRadius: 10, fontSize: 15, padding: 10, width: '13%'}}>Column Source</th>
		</tr>;
	}

	getDisplayNameElement(isEdit, column, updatedColumn) {
		if (!isEdit) return <div>{column.label}</div>;
		const currentValue = updatedColumn ? updatedColumn.label : column.label;
		return <div>
			<TextInputBox
				value={currentValue}
				onChange={(updatedValue) => {
					this.updateColumnProperty(column, updatedColumn, 'label', updatedValue);
				}}
				maxChars={40}
				isSimple={true}
				doNotAutoFocus={true}
				disabled={column.contactGroup}
			/>
		</div>;
	}

	getBooleanField(isEdit, column, updatedColumn, value, onChange) {
		if (!isEdit) return <div>{value ? 'True' : 'False'}</div>;
		return <div>
			<CheckboxButton
				checked={Boolean(value)}
				disabled={column.contactGroup}
				onChange={(event) => onChange(column, updatedColumn, event.target.checked)}
			/>
		</div>;
	}

	getColumnCategoryField(isEdit, column, updatedColumn) {
		const columnCategory = CustomColumnsView.TYPE_TO_NAME.get(column.type);
		if (!isEdit) return <div>{columnCategory.label}</div>;
		const currentValue = updatedColumn ? updatedColumn.type : column.type;
		const currentColumnCategory = CustomColumnsView.TYPE_TO_NAME.get(currentValue);
		return <div>
			<DropdownMenu
				options={CustomColumnsView.COLUMN_CATEGORY_OPTIONS}
				onSelect={(selectedCategory) => this.updateColumnProperty(column, updatedColumn, 'type', selectedCategory.key)}
				selectedOption={currentColumnCategory}
				width={120}
				disabled={column.contactGroup}
			/>
		</div>;
	}

	updateColumnProperty(column, updatedColumn, property, updatedValue) {

		if ('hidden' === property) {
			const getColumnByColumnName = this.state.customColumns.find(data => data.name === column.name);

			const hiddenValueUpdated = getColumnByColumnName.hidden !== updatedValue;

			if (updatedValue === false) {
				this.setState({isHiddenFieldUpdated: hiddenValueUpdated});
			}
			else {
				this.setState({
					isHiddenFieldUpdated: hiddenValueUpdated,
					isHiddenFieldUpdatedToTrue: hiddenValueUpdated && updatedValue

				});
			}
		}

		const objectToBeModified = updatedColumn ? updatedColumn : column;
		const modifiedObject = {
			...objectToBeModified,
			[property]: updatedValue
		};
		this.updateBulkEditMap(column, modifiedObject);
	}

	updateBulkEditMap(currentColumn, modifiedObject) {
		if (this.checkEquality(currentColumn, modifiedObject)) {
			this.removeCustomColumnFromBulkEditMapInState(modifiedObject);
		}
		else {
			this.addOrUpdatedCustomColumnInState(modifiedObject);
		}
	}

	checkEquality(customColumn, updatedCustomColumn) {
		if (customColumn === null && updatedCustomColumn === null) return true;
		if (customColumn === null || updatedCustomColumn === null) return false;
		return (
			customColumn.label === updatedCustomColumn.label &&
			Boolean(customColumn.hidden) === Boolean(updatedCustomColumn.hidden) &&
			Boolean(customColumn.isUniqueId) === Boolean(updatedCustomColumn.isUniqueId) &&
			Boolean(customColumn.isPiiSensitive) === updatedCustomColumn.isPiiSensitive &&
			customColumn.type === updatedCustomColumn.type &&
			customColumn.source === updatedCustomColumn.source
		);
	}

	render() {
		return (
			<ScrollingContainer divId={'custom-column-editor'}>
				{this.state.showEditInfoDialog && this.renderEditInfoDialog()}
				{this.state.showContactAddDialog && this.renderContactAddDialog()}
				{this.state.showContactRemoveDialog && this.renderContactRemoveDialog()}
				{this.state.showColumnHiddenInfoDialog && this.renderColumnHiddenInfoDialog()}
				<DetailHeader MaterialIcon={VerifiedUser}
							  title={this.state.title}
							  detailRight={<LinkButton label={'Admin Center'}
													   onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.ROUTE)}/>}/>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto'}}>
					{
						(this.state.loading) ? <Loading>Loading Custom Columns...</Loading> : this.renderColumnsView()
					}
				</div>
			</ScrollingContainer>
		);
	}
}

CustomColumnsView.propTypes = {
	authState: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(CustomColumnsView));
