
import {Check, DoNotDisturb, Download} from '@mui/icons-material';
import React from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import EnvHelper from '../../../helpers/EnvHelper';
import PagingBase from '../../../ui/lists/PagingBase';
import ChannelHelper from '../../../helpers/ChannelHelper';
import Loading from '../../../ui/Loading';
import CoSellActionListMetadataLive from './co_sell_components/CoSellActionListMetadataLive';
import {partnerTapTernary, partnerTapTernaryLight, partnerTapWhite} from '../../../styles/partnertap_theme';
import ActionButton from '../../../ui/buttons/ActionButton';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import CoSellSaveButtonLive from '../../../ui/buttons/CoSellSaveButtonLive';
import ReportHelper from '../../../helpers/ReportHelper';
import FeatureHelper, {FEATURE_CHANNEL_DOWNLOAD_REPORTS, FEATURE_COSELL_DOWNLOAD_ACTION_LISTS_AND_REPORTS} from '../../../helpers/FeatureHelper';
import Dialog from '../../../ui/Dialog';
import DownloadHelper from '../../../helpers/DownloadHelper';
import {Routes} from '../../../globals/Routes';

class CoSellActionListResultsPageLive extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.unmounted = false;
		this.state.loading = false;
		this.state.downloading = false;

		this.state.workflowName = null;
		this.state.filterSelectorFunction = CoSellEngineEndpoints.getCoSellAnalyticsFilters;
		this.state.reportTypes = null;
		this.state.filterSelectorMounted = false;
		this.state.showUpsellDialog = false;

		this.initData = this.initData.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
		this.selectReportType = this.selectReportType.bind(this);
	}

	async componentDidMount() {
		this.setState({loading: true});
		this.initData();
	}

	initData() {
		Promise.all([
			CoSellEngineEndpoints.getCoSellActionListMetadata(this.actionListMetadataPublicId),
			CoSellEngineEndpoints.getActiveActionListWorkflow(this.actionListMetadataPublicId),
			CoSellEngineEndpoints.fetchCosellAnalyticsReportTypes()
		])
		.then((results) => {
			if (this.unmounted) return;
			const activeWorkflow = results[1].payload;
			const reportTypes = results[2].payload;
			if (activeWorkflow?.dateExecuted) {
				let activeWorkflowName = activeWorkflow.sequenceDisplayName + " " + activeWorkflow.displayName;
				this.setState({activeWorkflow: activeWorkflow, workflowName: activeWorkflowName});
			}
			this.setState({actionListMetadata: results[0].payload, loading: false, reportTypes: reportTypes});
			if (!activeWorkflow?.dateExecuted) {
				return;
			}
			return this.reportType;
		})
		.then((reportType) => {
			if(!reportType) return;
			return CoSellEngineEndpoints.getCoSellAnalyticsColumns(this.actionListMetadataPublicId, reportType);
		})
		.then(result => {
			if(!result) return;
			const columnMetaDataList = result.payload.columns;
			this.initColumnData(columnMetaDataList);
			if (!columnMetaDataList?.length) {
				this.setState({noDataFoundMessage: result.payload.noDataFoundMessage});
			}
		} )
		.catch(error => {
			EnvHelper.serverError('Error from getCoSellActionListMetadata and columns for analytics.', error);
		});
	}

	selectReportType(selectedReportType) {
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_RESULTS_LIVE.PATH(this.actionListMetadataPublicId, selectedReportType.reportType));
	}

	get noDataMessage() {
		const messageStyle = {display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16, padding: 20};

		if (!this.state.activeWorkflow) {
			return (
				<div style={messageStyle}>
					<div style={{padding: 10}}>
						There is no active workflow for this action list.
					</div>
					<div style={{padding: 10}}>
						Once you begin a workflow, the results will appear here.
					</div>
				</div>);
		}
		if (!this.state.columnData?.length) {
			return (
				<div style={messageStyle}>
					{this.state.noDataFoundMessage?.map((r, i) => <div style={{padding: 10}} key={i}>{r}</div>)}
				</div>
			);
		}
		return super.noDataMessage;
	}

	get filterSelectorArgs() {
		return {
			coSellActionListMetadataPublicId: this.actionListMetadataPublicId,
			coSellReportType: this.reportType
		};
	}

	getRowData() {
		let params = this.processParameters();
		CoSellEngineEndpoints.getCoSellAnalyticsRecords(this.actionListMetadataPublicId, this.reportType, params)
		.then((result) => {
			if (this.unmounted) return;
			if (this.hasRowData(result)) {
				ChannelHelper.convertOtherJsonValues(result.payload);
				ChannelHelper.convertTypedValues(result.payload, this.columnData);
			}
			this.processData(params, result);
		})
		.catch(error => {
			this.processError(error);
		});
	}

	get coSellReportConfigLive() {
		let {actionListMetadata} = this.state;
		let params = this.processParameters(true);
		return {
			reportData: {
				actionListMetadataPublicId: this.actionListMetadataPublicId,
				reportType: this.reportType,
				filters: params.filters,
				search: params.search
			},
			partnerOrgPublicIds: [actionListMetadata.companyPartner],
			assetType: ReportHelper.getAssetTypeFromDisplayName(actionListMetadata.assetType),
			rowCount: this.state.rowCount
		};
	}

	get additionalToolbarButtons() {
		let buttons = [];
		buttons.push(<ActionButton key={'download_button_new'}
								   toolTip={'Create Downloadable CSV'}
								   onAction={this.handleDownload}
								   inProgress={this.state.downloading}
								   icon={<Download/>}/>);
		buttons.push(<CoSellSaveButtonLive callingPage={this} key={this.state.rowCount} cloneStrategy={true}/>);

		return buttons;
	}

	showUpsell() {
		this.setState({showUpsellDialog: true});
	}

	isReportDownloadEnabled() {
		const isChannelDownloadReportsEnabled = FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DOWNLOAD_REPORTS);
		const isCoSellDownloadReportsEnabled = FeatureHelper.isFeatureEnabled(FEATURE_COSELL_DOWNLOAD_ACTION_LISTS_AND_REPORTS);

		return isChannelDownloadReportsEnabled || (isCoSellDownloadReportsEnabled);
	}

	handleDownload() {
		if (!this.isReportDownloadEnabled()) {
			this.showUpsell();
			return;
		}

		let params = this.processParameters(true);
		this.setState({downloading: true});
		CoSellEngineEndpoints.getCoSellAnalyticsDownload(this.actionListMetadataPublicId, this.reportType, params.filters, params.search, DownloadHelper.getFields(this.state.columnData))
		.then(result => {
			this.setState({downloading: false});
		})
		.catch(error => {
			this.setState({downloading: false});
			this.processError(error);
		});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get actionListMetadataPublicId() {
		return this.props.match.params.actionListId;
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	get storageKeyBase() {
		return `co_sell_action_list_results_${this.reportType}_${this.actionListMetadataPublicId}`;
	}

	get title() {
		const {reportTypes} = this.state;
		let selectedReportType = reportTypes.find(report => report.reportType === this.reportType);
		return (
			<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 340, height: 55}}>
				<DropdownMenu title={'Report'}
							  width={300}
							  options={reportTypes || [{label: 'Loading Reports'}]}
							  optionLabel={'displayName'}
							  selectedOption={selectedReportType}
							  disabled={!reportTypes}
							  onSelect={(report) => this.selectReportType(report)}/>
			</div>);
	}

	get icon() {
		return null;
	}

	get filterSelectorConfig() {
		return super.filterSelectorConfig;
	}

	renderWorkflowName() {
		let {workflowName} = this.state;
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				padding: 10,
				gap: 10,
				borderRadius: 5,
				backgroundColor: partnerTapWhite
			}}>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					gap: 5,
					padding: 10,
					fontSize: 18,
					color: partnerTapTernary,
					backgroundColor: partnerTapTernaryLight,
					border: '1px solid ' + partnerTapTernary,
					borderRadius: 5
				}}>
					{workflowName ? <div><Check/> {workflowName}</div> : <div><DoNotDisturb/> No active workflow</div>}
				</div>
			</div>
		);
	}

	render() {
		let {actionListMetadata, activeWorkflow} = this.state;
		if (!actionListMetadata) return <Loading>Loading Report...</Loading>;

		let title = 'Upgrade Required';
		let message = (<>Downloading reports is a paid feature. Please reach out to <a href={'mailto:sales@partnertap.com'}>sales@partnertap.com</a> to
			upgrade.</>);

		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
				{this.state.showUpsellDialog &&
				 <Dialog title={title}
						 message={message}
						 yesAction={() => this.setState({showUpsellDialog: false})}
				 />}
				<CoSellActionListMetadataLive actionListMetadataPublicId={this.actionListMetadataPublicId} activeWorkflow={activeWorkflow}/>
				{this.renderWorkflowName()}
				{super.render()}
			</div>
		);
	}

}

export default withRouter(CoSellActionListResultsPageLive);
