import {Cancel, Check, Edit} from '@mui/icons-material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import DateHelper from '../../../../helpers/DateHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import ReportHelper from '../../../../helpers/ReportHelper';
import {partnerTapAlert, partnerTapDefaultText, partnerTapWhite} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';
import ScrimMessage from '../../../../ui/messages/ScrimMessage';
import TextInputBox from '../../../../ui/TextInputBox';
import {canUserEditActionLists} from '../CoSellEngineHelperLive';
import Loading from '../../../../ui/Loading';

class CoSellActionListMetadataLive extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editingActionListMetadata: null,
			savingActionListMetadata: false,
			loading: true
		};

		// Layout constants
		this.labelCellWidth1 = 100;
		this.labelCellWidth2 = 150;
		this.spacerCellWidth = 120;

		// Bind methods
		this.saveChanges = this.saveChanges.bind(this);
		this.handleStartEditing = this.handleStartEditing.bind(this);
		this.handleCancelEditing = this.handleCancelEditing.bind(this);
		this.updateEditingField = this.updateEditingField.bind(this);
	}

	async componentDidMount() {
		try {
			const {actionListMetadataPublicId} = this.props;
			const response = await CoSellEngineEndpoints.getCoSellActionListMetadata(actionListMetadataPublicId);

			this.setState({
				actionListMetadata: response.payload,
				loading: false
			});
		}
		catch (error) {
			this.setState({savingActionListMetadata: false, loading: false});
			EnvHelper.serverError('Error from saveChanges', error);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get isActionListUsed() {
		const {activeWorkflow} = this.props;
		return Boolean(activeWorkflow);
	}

	get canUserEditActionLists() {
		const {authState} = this.props;
		const {actionListMetadata} = this.state;
		return canUserEditActionLists(actionListMetadata, authState);
	}

	// Handle editing
	handleStartEditing() {
		const {actionListMetadata} = this.state;

		// Create a deep copy to avoid mutation issues
		this.setState({
			editingActionListMetadata: {...actionListMetadata}
		});
		window.dispatchEvent(new Event('resize')); // adjust for height change
	}

	handleCancelEditing() {
		this.setState({editingActionListMetadata: null});
		window.dispatchEvent(new Event('resize')); // adjust for height change
	}

	// Update fields in editing state
	updateEditingField(field, value) {
		this.setState(prevState => ({
			editingActionListMetadata: {
				...prevState.editingActionListMetadata,
				[field]: value
			}
		}));
	}

	// Save changes
	saveChanges() {
		const {editingActionListMetadata, actionListMetadata} = this.state;

		if (!actionListMetadata || !editingActionListMetadata) {
			return;
		}

		// Prepare update data
		const updatedData = {
			name: editingActionListMetadata.name.length > 3 ? editingActionListMetadata.name : actionListMetadata.name,
			description: editingActionListMetadata.description,
			expirationOnDate: editingActionListMetadata.expirationOnDate
		};

		this.setState({savingActionListMetadata: true, editingActionListMetadata: null});
		window.dispatchEvent(new Event('resize')); // adjust for height change

		CoSellEngineEndpoints.updateCoSellActionListMetadata(actionListMetadata.cosellActionListMetadataPublicId, updatedData)
		.then(() => {
			if (this.unmounted) return;
			this.setState({
				savingActionListMetadata: false,
				actionListMetadata: {
					...actionListMetadata,
					...updatedData
				}
			});
		})
		.catch((error) => {
			if (this.unmounted) return;
			this.setState({savingActionListMetadata: false});
			EnvHelper.serverError('Error from saveChanges', error);
		});
	}

	render() {
		const {activeWorkflow} = this.props;
		const {actionListMetadata} = this.state;
		const {editingActionListMetadata, savingActionListMetadata, loading} = this.state;

		if (loading) {
			return <Loading>Loading...</Loading>;
		}
		const isExpired = actionListMetadata.expirationOnDate < (new Date().getTime() / 1000);

		return (
			<div style={{display: 'flex', justifyContent: 'space-between', padding: 10, borderRadius: 5, backgroundColor: partnerTapWhite}}>
				{savingActionListMetadata && <ScrimMessage message="Saving Changes..."/>}

				<table cellSpacing={10} data-cy="action-list-metadata-table">
					<tbody>
					<tr>
						<td data-cy="action-list-name-label" style={{fontWeight: 'bold', width: this.labelCellWidth1}}>Action List:</td>
						<td data-cy="action-list-name-value">
							{editingActionListMetadata ? (
								<TextInputBox
									value={editingActionListMetadata.name}
									onChange={(value) => this.updateEditingField('name', value)}
									doNotAutoFocus={true}
									isSimple={true}
									minWidth={400}
									maxChars={255}
								/>
							) : actionListMetadata.name}
						</td>
						<td style={{width: this.spacerCellWidth}}/>
						<td data-cy="created-by-label" style={{fontWeight: 'bold', width: this.labelCellWidth2}}>Created By:</td>
						<td data-cy="created-by-value">
							{actionListMetadata.createdByPerson}
						</td>
					</tr>
					<tr>
						<td data-cy="description-label" style={{fontWeight: 'bold', width: this.labelCellWidth1}}>Description:</td>
						<td data-cy="description-value">
							{editingActionListMetadata ? (
								<TextInputBox
									value={editingActionListMetadata.description}
									onChange={(value) => this.updateEditingField('description', value)}
									doNotAutoFocus={true}
									isSimple={true}
									minWidth={400}
									rows={3}
									maxChars={255}
								/>
							) : (actionListMetadata.description || '-')}
						</td>
						<td style={{width: this.spacerCellWidth}}/>
						<td data-cy="created-on-label" style={{fontWeight: 'bold', width: this.labelCellWidth2}}>Created On:</td>
						<td data-cy="created-on-value">{DateHelper.epochToDate(actionListMetadata.createdOnDate)}</td>
					</tr>
					<tr>
						<td data-cy="partner-label" style={{fontWeight: 'bold', width: this.labelCellWidth1}}>Partner:</td>
						<td data-cy="partner-value">{actionListMetadata.companyPartner}</td>
						<td style={{width: this.spacerCellWidth}}/>
						<td data-cy="expires-on-label" style={{fontWeight: 'bold', width: this.labelCellWidth2}}>Expires On:</td>
						<td data-cy="expires-on-value" style={{
							color: isExpired ? partnerTapAlert : partnerTapDefaultText,
							fontWeight: isExpired ? 'bold' : 'normal'
						}}>
							{editingActionListMetadata ? (
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker value={moment.unix(editingActionListMetadata.expirationOnDate).toDate()}
												minDate={moment().add(30, 'days').toDate()}
												maxDate={moment().add(1, 'years').toDate()}
												format={'MM/dd/yyyy'}
												onChange={(value, context) => {
													if (value && !context.validationError) {
														this.updateEditingField('expirationOnDate', Math.floor(value.valueOf() / 1000));
													}
												}}/>
								</LocalizationProvider>
							) : DateHelper.epochToDate(actionListMetadata.expirationOnDate)}
						</td>
					</tr>
					<tr>
						<td data-cy="record-type-label" style={{fontWeight: 'bold', width: this.labelCellWidth1}}>Record Type:</td>
						<td data-cy="record-type-value">{ReportHelper.getAssetTypeDisplayName(actionListMetadata.assetType)}</td>
						<td style={{width: this.spacerCellWidth}}/>
						<td data-cy="workflow-started-by-label" style={{fontWeight: 'bold', width: this.labelCellWidth2}}>Workflow Started By:</td>
						<td data-cy="workflow-started-by-value">
							{activeWorkflow?.workflowStartedBy || '-'}
						</td>
					</tr>
					<tr>
						<td/>
						<td/>
						<td style={{width: this.spacerCellWidth}}/>
						<td data-cy="workflow-started-on-label" style={{fontWeight: 'bold', width: this.labelCellWidth2}}>Workflow Started On:</td>
						<td data-cy="workflow-started-on-value">
							{activeWorkflow ? DateHelper.epochToDate(activeWorkflow.dateExecuted) : '-'}
						</td>
					</tr>
					</tbody>
				</table>
				<div>
					{editingActionListMetadata ? (
						<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							<PrimaryButton
								label="SAVE"
								icon={<Check/>}
								fullWidth={true}
								onClick={this.saveChanges}
							/>
							<SecondaryButton
								label="CANCEL"
								icon={<Cancel/>}
								fullWidth={true}
								onClick={this.handleCancelEditing}
							/>
						</div>
					) : (
						<SecondaryButton
							label="EDIT"
							icon={<Edit/>}
							disabled={this.isActionListUsed || !this.canUserEditActionLists}
							onClick={this.handleStartEditing}
						/>
					)}
				</div>
			</div>
		);
	}
}

CoSellActionListMetadataLive.propTypes = {
	actionListMetadataPublicId: PropTypes.object.isRequired,
	activeWorkflow: PropTypes.object,
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(CoSellActionListMetadataLive));