import PropTypes from 'prop-types';
import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import {MenuItem} from '@mui/material';
import LinkButton from './LinkButton';
import Dialog from '../Dialog';

class AssetDetailNoteRecordActions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorElement: null,
			isDeleteDialogOpen: false
		};

		this.onOpenMenu = this.onOpenMenu.bind(this);
		this.onCloseMenu = this.onCloseMenu.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
	}

	onOpenMenu(event) {
		if (event) {
			event.stopPropagation();
		}
		this.setState({
			anchorElement: event.currentTarget
		});
	}

	onCloseMenu(event) {
		if (event) {
			event.stopPropagation();
		}
		this.setState({anchorElement: null});
	}

	onDeleteClick() {
		this.onCloseMenu();
		this.setState({isDeleteDialogOpen: true});
	}

	renderDeleteConfirmDialog() {
		return (
			<Dialog
				title={'Please Confirm'}
				message={
					<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
						<div style={{fontSize: 16}}>
							Are you sure you want to delete this note?
						</div>
					</div>
				}
				yesLabel={'YES'}
				yesAction={() => {
					// Call the parent's refreshNotes function which handles deletion
					this.props.refreshNotes();
					this.setState({isDeleteDialogOpen: false});
				}}
				noLabel={'NO'}
				noAction={() => this.setState({isDeleteDialogOpen: false})}
			/>
		);
	}

	render() {
		const {anchorElement, isDeleteDialogOpen} = this.state;

		return (
			<div>
				{isDeleteDialogOpen && this.renderDeleteConfirmDialog()}

				<div style={{textAlign: 'center'}}>
					<IconButton
						aria-label={'more'}
						onClick={this.onOpenMenu}>
						<MoreVertIcon/>
					</IconButton>
					<Menu
						id={'menu'}
						anchorEl={anchorElement}
						onClose={this.onCloseMenu}
						open={Boolean(anchorElement)}>
						<MenuItem key={'delete'}>
							<LinkButton
								label={'Delete'}
								onClick={this.onDeleteClick}
								disabled={!this.props.canShowDeleteButton}
							/>
						</MenuItem>
					</Menu>
				</div>
			</div>
		);
	}
}

AssetDetailNoteRecordActions.propTypes = {
	refreshNotes: PropTypes.func.isRequired,
	canShowDeleteButton: PropTypes.bool.isRequired
};

export default AssetDetailNoteRecordActions;