import React from 'react';
import {NoteAlt} from '@mui/icons-material';
import * as NotesEndpoints from '../../../endpoints/NotesEndpoints';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AssetDetailNoteRecordActions from '../../../ui/buttons/AssetDetailNoteRecordActions';
import FeatureHelper, {FEATURE_CHANNEL_CREATE_RECORD_NOTES, FEATURE_CHANNEL_DELETE_RECORD_NOTES} from '../../../helpers/FeatureHelper';

class NotesManager {

	constructor(component, assetType, assetIdParamName) {
		this.component = component;
		this.assetType = assetType;
		this.assetIdParamName = assetIdParamName;

		// Bind methods to maintain proper 'this' context
		this.fetchNotes = this.fetchNotes.bind(this);
		this.handleDeleteNote = this.handleDeleteNote.bind(this);
		this.handleAddNewNote = this.handleAddNewNote.bind(this);
		this.closeNotesDrawer = this.closeNotesDrawer.bind(this);
		this.renderAddNoteButton = this.renderAddNoteButton.bind(this);
		this.getNotesTableColumnData = this.getNotesTableColumnData.bind(this);
	}

	getNotesTableColumnData(currentPersonId) {
		return [
			{
				title: 'Actions',
				key: 'actions',
				active: true,
				renderFunction: (columnValue, rowData) => {
					const normalizedNotePersonId = rowData.createdByPersonId?.replaceAll('-', '');
					const canShowDeleteButton =
						(FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_CREATE_RECORD_NOTES) && currentPersonId === normalizedNotePersonId) ||
						FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DELETE_RECORD_NOTES);

					return (
						<AssetDetailNoteRecordActions
							refreshNotes={() => this.handleDeleteNote(rowData.notePublicId)}
							canShowDeleteButton={canShowDeleteButton}
						/>
					);
				}
			},
			{title: 'Partner Org', key: 'partnerName', active: true},
			{title: 'Created Date', key: 'createdDate', active: true},
			{title: 'Created By', key: 'createdBy', active: true},
			{title: 'Note', key: 'note', active: true},
			{title: 'Action List Name', key: 'actionListName', active: true}
		];
	}

	buildFetchNotesParams() {
		const assetId = this.component.props.match.params[this.assetIdParamName];

		return {
			page: 0,
			pageSize: 100, // We're not handling pagination yet on the UI for notes
			filters: {
				asset_object_id: assetId,
				asset_object_type: this.assetType
			}
		};
	}

	async fetchNotes() {
		if (this.component.unmounted) return {notes: [], totalElements: 0};

		const params = this.buildFetchNotesParams();

		try {
			const result = await NotesEndpoints.getAssetNotes(params);

			if (result?.payload) {
				const notes = result.payload.map(note => ({
					...note,
					createdDate: DateHelper.epochToDate(note.createdDate)
				}));

				// Extract totalElements from the metaData
				const totalElements = result.metaData?.totalElements || 0;

				return {notes, totalElements};
			}
			return {notes: [], totalElements: 0};
		}
		catch (error) {
			EnvHelper.serverError('Error fetching notes', error);
			return {notes: [], totalElements: 0};
		}
	}

	async handleDeleteNote(noteId) {
		try {
			// Delete the note from the API
			await NotesEndpoints.deleteAssetNote(noteId);

			// Create a new notes array without the deleted note
			const updatedNotes = this.component.state.notes.filter(note => note.notePublicId !== noteId);

			// Update the state with a new notes array and a new unique key
			// Also decrement the note count since we've deleted a note
			this.component.setState({
				notes: updatedNotes,
				noteCount: Math.max(0, this.component.state.noteCount - 1),
				notesTableKey: 'notes_table_' + Date.now()
			});
		}
		catch (error) {
			EnvHelper.serverError('Error deleting note', error);
		}
	}

	handleAddNewNote() {
		this.component.setState({showNotesDrawer: true});
	}

	closeNotesDrawer(reloadNotes = false) {
		this.component.setState({showNotesDrawer: false});

		// If notes were added or modified, refresh the notes list
		if (reloadNotes) {
			this.fetchNotes().then(({notes, totalElements}) => {
				this.component.setState({
					notes,
					noteCount: totalElements,
					notesTableKey: 'notes_table_' + Date.now()
				});
			});
		}
	}

	renderAddNoteButton() {
		return (
			<Tooltip title="Add new note">
				<IconButton onClick={this.handleAddNewNote} size="small">
					<NoteAlt/>
				</IconButton>
			</Tooltip>
		);
	}

	async initializeNotes() {
		const {notes, totalElements} = await this.fetchNotes();

		this.component.setState({
			notes,
			noteCount: totalElements,
			notesTableKey: 'notes_table_' + Date.now()
		});

		return {notes, totalElements};
	}
}

export default NotesManager;