import {ReadMore} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import AccountDetailHeader from '../../../app/channel_ecosystem/accounts/AccountDetailHeader';
import DashboardTable from '../../../app/channel_ecosystem/shared/DashboardTable';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import {ACCOUNTS_ASSET, COLUMN_KEY_OPPORTUNITY_NAME} from '../../../globals/Enums';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import PartnerStatusHelper from '../../../helpers/PartnerStatusHelper';
import {CHANNEL_REPORT_TYPE_BASE_ACCOUNTS} from '../../../helpers/ReportHelper';
import Dialog from '../../../ui/Dialog';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import DetailHeader from '../shared/DetailHeader';
import NoteDrawer from '../../../ui/NoteDrawer';
import NotesManager from '../notes/NotesManager';
import FeatureHelper, {FEATURE_CHANNEL_CREATE_RECORD_NOTES, FEATURE_CHANNEL_VIEW_RECORD_NOTES} from '../../../helpers/FeatureHelper';

class AccountDetail extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			details: {},
			showAlertMessage: false,
			alertMessage: '',
			partnershipCreated: false,
			partnerCompaniesList: [],
			salesOwnerList: [],
			partnerOwnerList: [],
			opportunitiesList: [],
			notes: [],
			// Add a key to force re-rendering of the notes table
			notesTableKey: 'notes_table_' + Date.now(),
			showNotesDrawer: false,
			noteCount: 0,
			currentPersonId: this.props.authState.person.id
		};

		// Initialize the NotesManager with this component instance, asset type, and route param name
		this.notesManager = new NotesManager(this, ACCOUNTS_ASSET, 'crmAccountId');

		// Bind the NotesManager methods to use in this component
		this.fetchNotes = this.notesManager.fetchNotes;
		this.handleDeleteNote = this.notesManager.handleDeleteNote;
		this.handleAddNewNote = this.notesManager.handleAddNewNote;
		this.closeNotesDrawer = this.notesManager.closeNotesDrawer;
		this.renderAddNoteButton = this.notesManager.renderAddNoteButton;

		// Bind other component methods
		this.handlePartnershipAction = this.handlePartnershipAction.bind(this);
	}

	async componentDidMount() {
		await this.fetchDashboard();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get matchedPartnerTableColumnData() {
		return [
			{title: 'Partner Company', key: 'companyName', isPartnerData: true, active: true},
			{title: 'Partner Is Customer', key: 'partnerIsCustomerField', isPartnerData: true, active: true, type: 'boolean'},
			{title: 'Open Opps', key: 'openOppCount', isPartnerData: true, active: true},
			{title: 'Closed Won Opps', key: 'closedWonOppCount', isPartnerData: true, active: true},
			{title: 'Chats', key: 'chatCount', active: true}
		];
	}

	get salesOwnerTableColumnData() {
		return [
			{title: 'Name', key: 'name', active: true},
			{title: 'Title', key: 'title', active: true},
			{title: 'Phone #', key: 'phone', active: true},
			{title: 'Email', key: 'email', active: true}
		];
	}

	get partnerOwnerTableColumnData() {
		return [
			{title: 'Name', key: 'name', isPartnerData: true, active: true},
			{title: 'Title', key: 'title', isPartnerData: true, active: true},
			{title: 'Company Name', key: 'companyName', isPartnerData: true, active: true},
			{title: 'Phone #', key: 'phone', isPartnerData: true, active: true},
			{title: 'Email', key: 'email', isPartnerData: true, active: true},
			{
				title: 'Actions',
				key: ACTIONS,
				active: true,
				renderFunction: (columnValue, rowData) => PartnerStatusHelper.renderChannelPartnershipAction(rowData, this.handlePartnershipAction)
			}
		];
	}

	get opportunitiesTableColumnData() {
		return [
			{title: 'Name', key: COLUMN_KEY_OPPORTUNITY_NAME, active: true},
			{title: 'Stage Name', key: 'stageName', active: true},
			{title: 'Status', key: 'opportunityStatus', active: true},
			{title: 'Owner Name', key: 'opportunityOwnerName', active: true},
			{title: 'Is Won', key: 'won', type: 'boolean', active: true},
			{title: 'Amount', key: 'amount', type: 'currency', active: true}
		];
	}

	get notesTableColumnData() {
		const {currentPersonId} = this.state;
		return this.notesManager.getNotesTableColumnData(currentPersonId);
	}

	handlePartnershipAction(created, message) {
		this.setState({showAlertMessage: true, alertMessage: message, partnershipCreated: created, loading: true});
		this.fetchDashboard();
	}

	async fetchDashboard() {
		try {
			const result = await ChannelMappingEndpoints.getDashboard({
				crmAccountId: this.props.match.params.crmAccountId,
				channelReportType: CHANNEL_REPORT_TYPE_BASE_ACCOUNTS
			});

			if (this.unmounted) return;

			let {details, partnerCompaniesList, salesOwnerList, partnerOwnerList, opportunitiesList} = result.payload;
			partnerOwnerList.forEach((partnerItem) => {
				partnerItem.partnerOrganizationId = details.orgId;
				partnerItem.partnerOwnerId = details.ownerId;
			});

			// Only fetch notes if the feature is enabled
			let notes = [];
			let noteCount = 0;

			if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_RECORD_NOTES)) {
				const notesResult = await this.fetchNotes();
				notes = notesResult.notes;
				noteCount = notesResult.totalElements;
			}

			this.setState({
				loading: false,
				details: details,
				partnerCompaniesList,
				salesOwnerList,
				partnerOwnerList,
				opportunitiesList,
				notes,
				noteCount,
				notesTableKey: 'notes_table_' + Date.now()
			});
		}
		catch (error) {
			EnvHelper.serverError('Error from getAccountDetails', error);
			this.setState({loading: false});
		}
	}

	render() {
		let {
			loading,
			showAlertMessage,
			alertMessage,
			partnershipCreated,
			details,
			partnerCompaniesList,
			salesOwnerList,
			partnerOwnerList,
			opportunitiesList,
			notes,
			notesTableKey,
			showNotesDrawer,
			noteCount
		} = this.state;

		if (loading) return <Loading>Loading Account Detail...</Loading>;

		return (
			<ScrollingContainer divId={'ecosystem_mapped_account_detail'}>
				<DetailHeader title={'Account Details'} MaterialIcon={ReadMore}/>
				<AccountDetailHeader details={details}/>
				<div style={{overflow: 'scroll', marginLeft: 5, marginRight: 5}}>
					<DashboardTable
						key={'account_detail_matched_partners'}
						title={'Matched Partners'}
						columnData={this.matchedPartnerTableColumnData}
						rowData={partnerCompaniesList}
						hasScrollingContainerParent={true}
					/>
					<DashboardTable
						key={'account_detail_sales_owners'}
						title={'Sales Owners'}
						columnData={this.salesOwnerTableColumnData}
						rowData={salesOwnerList}
						hasScrollingContainerParent={true}
					/>
					<DashboardTable
						key={'account_detail_partner_owners'}
						title={'Partner Owners'}
						columnData={this.partnerOwnerTableColumnData}
						rowData={partnerOwnerList}
						hasScrollingContainerParent={true}
					/>
					<DashboardTable
						key={'account_detail_my_opps'}
						title={'My Opps'}
						columnData={this.opportunitiesTableColumnData}
						rowData={opportunitiesList}
						hasScrollingContainerParent={true}
					/>

					{/* Notes table */}
					{FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_RECORD_NOTES) && (
						<DashboardTable
							key={notesTableKey}
							title={'Notes'}
							elementRight={FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_CREATE_RECORD_NOTES) ? this.renderAddNoteButton() : undefined}
							columnData={this.notesTableColumnData}
							rowData={notes}
							hasScrollingContainerParent={true}
							itemCount={noteCount > 0 ? noteCount : undefined}
						/>
					)}
				</div>
				{showAlertMessage &&
				 <Dialog title={partnershipCreated ? 'Success!' : 'Error!'}
						 message={alertMessage}
						 yesAction={() => this.setState({showAlertMessage: false})}/>}

				{/* Notes Drawer */}
				{showNotesDrawer &&
				 <NoteDrawer
					 open={showNotesDrawer}
					 onClose={(reloadNotes) => this.closeNotesDrawer(reloadNotes)}
					 assetObjectId={this.props.match.params.crmAccountId}
					 assetType={ACCOUNTS_ASSET}
				 />}
			</ScrollingContainer>
		);
	}
}

AccountDetail.propTypes = {
	authState: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(AccountDetail));