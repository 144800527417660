import PropTypes from 'prop-types';
import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import {MenuItem} from '@mui/material';
import LinkButton from '../../../../ui/buttons/LinkButton';
import NoteDrawer from '../../../../ui/NoteDrawer';
import FeatureHelper, {FEATURE_CHANNEL_VIEW_RECORD_NOTES} from '../../../../helpers/FeatureHelper';

class CoSellActionListRecordActions extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {anchorElement: null};
		this.onOpenMenu = this.onOpenMenu.bind(this);
		this.onCloseMenu = this.onCloseMenu.bind(this);
		this.closeNotesDrawer = this.closeNotesDrawer.bind(this);
		this.onViewNotesClick = this.onViewNotesClick.bind(this);
	}

	onOpenMenu(event) {
		if (event) {
			event.stopPropagation();
		}
		this.setState({
			anchorElement: event.currentTarget,
			showNotesDrawer: false
		});
	}

	onCloseMenu(event) {
		if (event) {
			event.stopPropagation();
		}
		this.setState({anchorElement: null});
	}

	closeNotesDrawer(reloadTable) {
		this.setState({
			showNotesDrawer: false
		});
		if (reloadTable) {
			this.props.refreshTable();
		}
	}

	onViewNotesClick() {
		this.onCloseMenu();
		this.setState({showNotesDrawer: true});
	}

	render() {
		const {actionListMetadataPublicId, displayPartnerDropdown, actionListPartnerOrgs, assetObjectId, assetType} = this.props;
		const {anchorElement, showNotesDrawer} = this.state;

		return (
			<div>
				{showNotesDrawer &&
				 <NoteDrawer
					 open={showNotesDrawer}
					 onClose={this.closeNotesDrawer}
					 actionListMetadataPublicId={actionListMetadataPublicId}
					 displayPartnerDropdown={displayPartnerDropdown}
					 actionListPartnerOrgs={actionListPartnerOrgs}
					 assetObjectId={assetObjectId}
					 assetType={assetType}
				 />}
				<div style={{textAlign: 'center'}}>
					<IconButton
						aria-label={'more'}
						onClick={this.onOpenMenu}>
						<MoreVertIcon/>
					</IconButton>
					<Menu
						id={'menu'}
						anchorEl={anchorElement}
						onClose={this.onCloseMenu}
						open={Boolean(anchorElement)}>
						<MenuItem key={'addViewNotes'}>
							<LinkButton label={'Add/View Notes'}
										onClick={this.onViewNotesClick}
										disabled={!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_RECORD_NOTES)}/>
						</MenuItem>
					</Menu>
				</div>
			</div>
		);
	}
}

CoSellActionListRecordActions.propTypes = {
	actionListMetadataPublicId: PropTypes.string.isRequired,
	displayPartnerDropdown: PropTypes.bool.isRequired,
	actionListPartnerOrgs: PropTypes.array.isRequired,
	assetObjectId: PropTypes.string.isRequired,
	assetType: PropTypes.string.isRequired,
	refreshTable: PropTypes.func.isRequired
};

export default CoSellActionListRecordActions;
