import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import * as NotesEndpoints from '../endpoints/NotesEndpoints';
import EnvHelper from '../helpers/EnvHelper';
import DropdownMenu from './selectors/DropdownMenu';
import Dialog from './Dialog';
import PopoverSearchList from './PopoverSearchList';
import * as ChannelMappingEndpoints from '../endpoints/ChannelMappingEndpoints';
import PrimaryButton from './buttons/PrimaryButton';
import TextInputBox from './TextInputBox';
import DateHelper from '../helpers/DateHelper';
import FeatureHelper, {FEATURE_CHANNEL_CREATE_RECORD_NOTES, FEATURE_CHANNEL_DELETE_RECORD_NOTES} from '../helpers/FeatureHelper';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const OTHER_PARTNER_ID = 'OTHER';
const OTHER_PARTNER_NAME = 'SELECT PARTNER ORG';

class NoteDrawer extends Component {
	constructor(props) {
		super(props);

		const partnerOrgs = Array.isArray(props.actionListPartnerOrgs) ? props.actionListPartnerOrgs : [];

		this.state = {
			notes: [],
			newNote: '',
			selectedPartnerFromDropDown: '',
			selectedPartnerFromPopup: null,
			isOtherPartnerDialogOpen: false,
			noteToDelete: null,
			isDeleteDialogOpen: false,
			partnerDropdownOptions: [
				{partnerOrgPublicId: OTHER_PARTNER_ID, partnerOrgName: OTHER_PARTNER_NAME},
				...partnerOrgs
			],
			currentPersonId: this.props.authState.person.id
		};

		this.handleAddNewNote = this.handleAddNewNote.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.handleNoteChange = this.handleNoteChange.bind(this);
		this.handlePartnerChange = this.handlePartnerChange.bind(this);
		this.handleSelectPartnerFromPopup = this.handleSelectPartnerFromPopup.bind(this);
	}

	async componentDidMount() {
		const {displayPartnerDropdown} = this.props;

		// We fetch and display the notes only for action list notes not for account and opp details pages
		if (this.props.actionListMetadataPublicId) {
			await this.fetchNotes();
		}

		if (displayPartnerDropdown === true) {
			await this.getPartnerOrgs();
		}
	}

	async fetchNotes() {
		const params = this.buildFetchNotesParams();

		try {
			const result = await NotesEndpoints.getAssetNotes(params);

			if (result?.payload) {
				const notes = result.payload;
				this.setState({notes});
			}
		}
		catch (error) {
			EnvHelper.serverError('Error fetching notes', error);
		}
	}

	buildFetchNotesParams() {
		const {actionListMetadataPublicId, assetObjectId, assetType} = this.props;

		return {
			page: 0,
			pageSize: 100, // We're not handling pagination yet on the UI for notes
			filters: {
				action_list_public_id: actionListMetadataPublicId,
				asset_object_id: assetObjectId,
				asset_object_type: assetType
			}
		};
	}

	handleNoteChange(event) {
		this.setState({newNote: event});
	}

	handlePartnerChange(event) {
		this.setState({selectedPartnerFromDropDown: event});

		if (event.partnerOrgPublicId === OTHER_PARTNER_ID) {
			this.setState({isOtherPartnerDialogOpen: true});
		}
	}

	handleSelectPartnerFromPopup() {
		const {selectedPartnerFromPopup, partnerDropdownOptions} = this.state;

		if (selectedPartnerFromPopup) {
			const newOption = {
				partnerOrgPublicId: selectedPartnerFromPopup.companyPartnerPublicId,
				partnerOrgName: selectedPartnerFromPopup.name
			};

			this.setState(prevState => ({
				partnerDropdownOptions: [
					...prevState.partnerDropdownOptions,
					newOption
				],
				selectedPartnerFromDropDown: newOption,
				isOtherPartnerDialogOpen: false
			}));
		}
	}

	async handleAddNewNote() {
		const {newNote, selectedPartnerFromDropDown} = this.state;
		const {actionListMetadataPublicId, assetObjectId, assetType, displayPartnerDropdown, onClose} = this.props;
		const partnerOrgs = Array.isArray(this.props.actionListPartnerOrgs) ? this.props.actionListPartnerOrgs : [];

		if (newNote.trim() && (displayPartnerDropdown === false || selectedPartnerFromDropDown)) {
			const payload = {
				coSellActionListMetadataPublicId: actionListMetadataPublicId,
				assetObjectId: assetObjectId,
				assetType: assetType,
				note: newNote.trim(),
				partnerPublicId: displayPartnerDropdown === true ? selectedPartnerFromDropDown.partnerOrgPublicId : undefined
			};

			try {
				const result = await NotesEndpoints.createAssetNote(payload);

				if (result?.payload) {
					const createdNote = result.payload;
					this.setState((prevState) => ({
						notes: [...prevState.notes, createdNote],
						newNote: '',
						selectedPartnerFromDropDown: '',
						partnerDropdownOptions: [
							{partnerOrgPublicId: OTHER_PARTNER_ID, partnerOrgName: OTHER_PARTNER_NAME},
							...partnerOrgs
						]
					}));

					// If we're not in an action list context, close the drawer and signal a refresh is needed
					if (!actionListMetadataPublicId && onClose) {
						onClose(true); // Pass true to indicate notes were added
					}
				}
			}
			catch (error) {
				EnvHelper.serverError('Error creating note', error);
			}
		}
	}

	onDeleteClick(note) {
		this.setState({
			noteToDelete: note,
			isDeleteDialogOpen: true
		});
	}

	async handleDeleteNote(noteToDelete) {
		try {
			await NotesEndpoints.deleteAssetNote(noteToDelete.notePublicId);
			this.setState(prevState => ({
				notes: prevState.notes.filter(note => note.notePublicId !== noteToDelete.notePublicId),
				noteToDelete: null,
				isDeleteDialogOpen: false
			}));
		}
		catch (error) {
			EnvHelper.serverError('Error deleting note', error);
		}
	}

	async getPartnerOrgs() {
		try {
			const result = await ChannelMappingEndpoints.getChannelPartnerOrgs();
			const {payload} = result;

			this.setState({orgList: payload});
		}
		catch (error) {
			EnvHelper.serverError('Error fetching partner orgs', error);
		}
	}

	renderSelectPartnerOrgPopup() {
		const {orgList, selectedPartnerFromPopup} = this.state;
		return (
			<Dialog title={'Select Partner Org'}
					message={
						<div style={{display: 'flex', flexDirection: 'column', gap: 20, minWidth: 400}}>
							<Fragment>
								<PopoverSearchList label={'Select Partner Org'}
												   list={orgList}
												   preselectedItem={selectedPartnerFromPopup}
												   labelRenderer={(org) => org.name}
												   valueRenderer={(org) => org.orgCode}
												   onItemSelected={(org) => {
													   this.setState({
														   selectedPartnerFromPopup: org
													   });
												   }}
												   searchByObjectKeys={['name']}
												   width={400}/>
							</Fragment>
						</div>
					}
					yesLabel={'SAVE'}
					yesAction={this.handleSelectPartnerFromPopup}
					noLabel={'CANCEL'}
					noAction={() => {
						this.setState({
							isOtherPartnerDialogOpen: false,
							selectedPartnerFromDropDown: ''
						});
					}}/>
		);
	}

	renderDeleteConfirmDialog() {
		const {noteToDelete} = this.state;

		if (!noteToDelete) return null;

		return (
			<Dialog
				title={'Please Confirm'}
				message={
					<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
						<div style={{fontSize: 16}}>
							Are you sure you want to delete this note?
						</div>
					</div>
				}
				yesLabel={'YES'}
				yesAction={() => this.handleDeleteNote(noteToDelete)}
				noLabel={'NO'}
				noAction={() => this.setState({isDeleteDialogOpen: false, noteToDelete: null})}
			/>
		);
	}

	renderNotes() {
		const {notes, currentPersonId} = this.state;

		return (
			<div style={{flex: 1, overflowY: 'auto'}}>
				{notes.map((note) => {
					const normalizedNotePersonId = note.createdByPersonId?.replaceAll('-', '');
					const canShowDeleteButton =
						(FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_CREATE_RECORD_NOTES) && currentPersonId === normalizedNotePersonId) ||
						FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DELETE_RECORD_NOTES);

					return (
						<div key={note.notePublicId} style={{
							marginBottom: 12,
							padding: 12,
							border: '1px solid #ccc',
							borderRadius: 8,
							position: 'relative'
						}}>
							<div>
								<strong>Partner:</strong> {note.partnerName} <br/>
								<strong>Created by:</strong> {note.createdBy} <br/>
								<strong>Created date:</strong> {DateHelper.epochToDate(note.createdDate)} <br/>
								<div style={{marginTop: 8}}>{note.note}</div>
							</div>
							{canShowDeleteButton && (
								<IconButton
									onClick={() => this.onDeleteClick(note)}
									style={{
										position: 'absolute',
										right: 4,
										top: 4
									}}
								>
									<DeleteIcon fontSize="small"/>
								</IconButton>
							)}
						</div>
					);
				})}
			</div>
		);
	}

	renderNoteInput() {
		const {newNote, selectedPartnerFromDropDown} = this.state;
		const {displayPartnerDropdown} = this.props;

		return (
			<div style={{display: 'flex', marginTop: 8, gap: '8px'}}>
				<TextInputBox
					value={newNote}
					hintText={'Enter note...'}
					maxChars={50000}
					onChange={this.handleNoteChange}
					minWidth={340}
					maxWidth={340}
					multiLine={true}
				/>
				<PrimaryButton
					key={'save'}
					label={'Save'}
					onClick={this.handleAddNewNote}
					disabled={
						!newNote.trim() ||
						(displayPartnerDropdown === true && !selectedPartnerFromDropDown)
					}
				/>
			</div>
		);
	}

	renderPartnerDropdown() {
		const {displayPartnerDropdown} = this.props;
		const {selectedPartnerFromDropDown, partnerDropdownOptions} = this.state;

		if (displayPartnerDropdown === false) {
			return null;
		}

		return (
			<div style={{display: 'flex', marginTop: 16}}>
				<DropdownMenu
					title={'Select Partner'}
					options={partnerDropdownOptions}
					optionLabel={'partnerOrgName'}
					selectedOption={selectedPartnerFromDropDown}
					onSelect={this.handlePartnerChange}
					width={340}
				/>
			</div>
		);
	}

	render() {
		const {open, onClose} = this.props;

		return (
			<Drawer
				anchor="right"
				open={open}
				onClose={onClose}
			>
				<div style={{
					width: 400,
					padding: 16,
					display: 'flex',
					flexDirection: 'column',
					height: '100%'
				}}>
					{/* Render fetched notes */}
					{this.renderNotes()}

					{/* Render dropdown */}
					{FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_CREATE_RECORD_NOTES) && this.renderPartnerDropdown()}

					{/* Render Note Input */}
					{FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_CREATE_RECORD_NOTES) && this.renderNoteInput()}

					{this.state.isOtherPartnerDialogOpen && this.renderSelectPartnerOrgPopup()}
					{this.state.isDeleteDialogOpen && this.renderDeleteConfirmDialog()}
				</div>
			</Drawer>
		);
	}

}

NoteDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	actionListMetadataPublicId: PropTypes.string,
	actionListPartnerOrgs: PropTypes.arrayOf(
		PropTypes.shape({
			partnerOrgPublicId: PropTypes.string.isRequired,
			partnerOrgName: PropTypes.string.isRequired
		})
	),
	assetObjectId: PropTypes.string,
	assetType: PropTypes.string,
	displayPartnerDropdown: PropTypes.bool
};

NoteDrawer.defaultProps = {
	displayPartnerDropdown: true
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(NoteDrawer));
