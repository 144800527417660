import {Routes} from '../globals/Routes';
import {DEEP_LINK_KEY, LAST_ROUTE_KEY, LOGIN_TOKEN_KEY} from '../globals/StorageKeys';
import EnvHelper from '../helpers/EnvHelper';
import * as AuthActions from '../redux/AuthActions';
import PersistenceHelper from './PersistenceHelper';
import 'whatwg-fetch';
import {CO_SELL_CONCIERGE} from '../globals/Enums';
import FeatureHelper from './FeatureHelper';

export const MEDIA_TYPE_APPLICATION_JSON = 'application/json';
export const MEDIA_TYPE_MULTIPART = 'multipart/form-data';
export const MEDIA_TYPE_TEXT_PLAIN = 'text/plain';
export const MEDIA_TYPE_TEXT_CSV = 'text/csv';

export const ALLOWED_SPOOF_POST_ENDPOINT_SUFFIXES = [
	'download',
	'dashboard',
	'columns',
	'records',
	'filterdata',
	'filter-data',
	'details',
	'list'
];
export const ALLOWED_SPOOF_POST_ENDPOINT_PATHS = ['cosell-workflow/action-list-summary/action-list'];

export const GET_CANCELLED_BY_BROWSER = 'Error: GET cancelled by browser';

function headers(payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON, accept = MEDIA_TYPE_APPLICATION_JSON) {
	let headers = {'Accept': accept};
	if (payload) {
		headers['JsonPayload'] = encodeURIComponent(JSON.stringify(payload));
	}
	if (contentType !== MEDIA_TYPE_MULTIPART) {
		headers['Content-Type'] = contentType;
	}
	if (EnvHelper.token) {
		headers['Authorization'] = EnvHelper.token;
	}
	return headers;
}

export function sendPost(endpoint, payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON) {
	return handleSendPost(EnvHelper.serviceUrl + endpoint, payload, contentType);
}

export function sendPostReport(endpoint, payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON) {
	return handleSendPost(EnvHelper.reportUrl + versionReportRequestEndpoint(endpoint), payload, contentType);
}

export function sendPostApiKeyGenerator(endpoint, payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON) {
	return handleSendPost(EnvHelper.apiKeyGeneratorUrl + endpoint, payload, contentType);
}

export function sendPostCoSellActionListMetadata(endpoint, payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON) {
	return handleSendPost(EnvHelper.coSellActionListUrl + endpoint, payload, contentType);
}

export function sendPutCoSellActionListMetadata(endpoint, payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON) {
	return handlePut(EnvHelper.coSellActionListUrl + endpoint, payload, contentType);
}

export function sendPostCoSellLanding(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleSendPost(EnvHelper.coSellLandingUrl + endpoint, payload, accept);
}

export function sendPostCoSellActionListWorkflows(endpoint, payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON) {
	return handleSendPost(EnvHelper.coSellWorkflowUrl + endpoint, payload, contentType);
}

export function sendPutCoSellActionListWorkflows(endpoint, payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON) {
	return handlePut(EnvHelper.coSellWorkflowUrl + endpoint, payload, contentType);
}

export function sendPostCoSellAnalytics(endpoint, payload = null, contentType = MEDIA_TYPE_APPLICATION_JSON) {
	return handleSendPost(EnvHelper.coSellAnalyticsUrl + endpoint, payload, contentType);
}

export function sendPostCoSellAnalyticsDownload(endpoint, payload = null, filename = "report.csv", contentType = MEDIA_TYPE_APPLICATION_JSON, accepts = MEDIA_TYPE_TEXT_CSV) {
	return handleSendPostDownload(EnvHelper.coSellAnalyticsUrl + endpoint, payload, filename, contentType);
}

export function sendPostNote(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleSendPost(EnvHelper.notesUrl + endpoint, payload, accept);
}

function handleSendPost(endpoint, payload, contentType) {
	if (EnvHelper.isSpoofing && !allowSpoof(endpoint)) {
		console.warn('Spoofing user! POST updates are blocked.', endpoint);
		return Promise.resolve();
	}

	let body;
	if (contentType === MEDIA_TYPE_MULTIPART) {
		let formData = new FormData();
		formData.append('file', payload);
		body = formData;
	}
	else {
		body = JSON.stringify(payload);
	}

	return fetch(endpoint, {method: 'POST', headers: headers(null, contentType), body: body})
	.then((response) => {
		setAuthHeader(response.headers);
		if (response.status >= 200 && response.status <= 299) {
			return response.text();
		}
		else {
			return handleErrorResponse(response);
		}
	})
	.then((text) => {
		if (text) {
			let json = JSON.parse(text);
			if (json.pageable) {
				let metaData = {
					numberOfElements: json.numberOfElements || 0,
					totalElements: json.totalElements || 0,
					totalPages: json.totalPages,
					last: json.last,
					size: json.size,
					number: json.number,
					first: json.first,
					pageable: json.pageable
				};
				return {payload: json.content, metaData: metaData};
			}
			return {payload: json, message: json.message};
		}
		return {payload: [], message: 'valid empty response'};
	});
}

function handleSendPostDownload(endpoint, payload, fileName, contentType) {
	if (EnvHelper.isSpoofing && !allowSpoof(endpoint)) {
		console.warn('Spoofing user! POST updates are blocked.', endpoint);
		return Promise.resolve();
	}

	let body = JSON.stringify(payload);

	return fetch(endpoint, {method: 'POST', headers: headers(null, contentType, MEDIA_TYPE_TEXT_CSV), body: body})
	.then((response) => {
		setAuthHeader(response.headers);
		if (response.status >= 200 && response.status <= 299) {
			fileName = getFileNameFromHeader(fileName, response.headers);
			return response.blob();
		}
		else {
			return handleErrorResponse(response);
		}
	})
	.then((blob) => {
		// Handle the CSV blob here
		// For example, to trigger a download:
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		return {payload: [], message: 'Download Complete'};
	});
}

function getFileNameFromHeader(defaultFileName, headers) {
	let filename = defaultFileName;
	let contentDisposition = headers.get('Content-Disposition');
	let filenameMatch = contentDisposition && contentDisposition.match(/filename="?(.+)"?/i);
	if (filenameMatch) {
		filename = filenameMatch[1];
	}
	return filename;
}

function allowSpoof(endpoint) {
	let pathFolder = endpoint.split('/');
	let lastSegment = pathFolder[pathFolder.length - 1];
	return Boolean(ALLOWED_SPOOF_POST_ENDPOINT_SUFFIXES.find((allowedEndpoint) => lastSegment.indexOf(allowedEndpoint) === 0)) ||
		   ALLOWED_SPOOF_POST_ENDPOINT_PATHS.some((allowedPath) => endpoint.includes(allowedPath)) ||
		   FeatureHelper.roleCode === CO_SELL_CONCIERGE;
}

export function sendGet(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleGet(EnvHelper.serviceUrl + endpoint, payload, accept);
}

export function sendGetReport(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleGet(EnvHelper.reportUrl + endpoint, payload, accept);
}

export function sendGetCoSellActionListMetadata(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleGet(EnvHelper.coSellActionListUrl + endpoint, payload, accept);
}

export function sendGetCoSellActionListWorkflows(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleGet(EnvHelper.coSellWorkflowUrl + endpoint, payload, accept);
}

export function sendGetCoSellLanding(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleGet(EnvHelper.coSellLandingUrl + endpoint, payload, accept);
}

export function sendGetApiKeyGenerator(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleGet(EnvHelper.apiKeyGeneratorUrl + endpoint, payload, accept);
}

export function sendGetCoSellAnalytics(endpoint, payload = null, accept = MEDIA_TYPE_APPLICATION_JSON) {
	return handleGet(EnvHelper.coSellAnalyticsUrl + endpoint, payload, accept);
}

function handleGet(endpoint, payload, accept) {
	return fetch(endpoint, {method: 'GET', headers: headers(payload, MEDIA_TYPE_APPLICATION_JSON, accept)})
	.then((response) => {
		setAuthHeader(response.headers);
		if (response.status >= 200 && response.status <= 299) {
			return response.text();
		}
		else {
			return handleErrorResponse(response);
		}
	})
	.then((text) => {
		if (text) {
			if (accept === MEDIA_TYPE_TEXT_PLAIN || accept === MEDIA_TYPE_TEXT_CSV) {
				return {payload: text};
			}
			let json = JSON.parse(text);
			if (json.error) {
				throw new Error('Error from GET json: ' + endpoint + ', ' + json.error + ', ' + json.message);
			}
			else if (json.pageable) {
				let metaData = {
					numberOfElements: json.numberOfElements || 0,
					totalElements: json.totalElements || 0,
					totalPages: json.totalPages,
					first: json.first,
					last: json.last,
					size: json.size,
					number: json.number,
					pageable: json.pageable
				};
				return {payload: json.content, metaData: metaData};
			}
			return {payload: json};
		}
		return {payload: [], message: 'valid empty response'};
	})
	.catch((error) => {
		if (getCanceledByBrowser(error)) {
			console.error('Error from GET: The user or browser aborted the request prior to completion', endpoint);
			return GET_CANCELLED_BY_BROWSER;
		}
		else {
			throw error;
		}
	});
}

export function sendPut(endpoint, payload) {
	return handlePut(EnvHelper.serviceUrl + endpoint, payload);
}

export function handlePut(endpoint, payload) {
	if (EnvHelper.isSpoofing && !allowSpoof(endpoint)) {
		console.warn('Spoofing user! PUT updates are blocked', endpoint);
		return Promise.resolve();
	}
	return fetch(endpoint, {method: 'PUT', headers: headers(), body: JSON.stringify(payload)})
	.then((response) => {
		if (response.ok) {
			return [];
		}
		else {
			return handleErrorResponse(response);
		}
	});
}

export function sendPutAoiKeyGenerator(endpoint, payload) {
	return handlePut(EnvHelper.apiKeyGeneratorUrl + endpoint, payload);
}

export function sendDelete(endpoint) {
	return handleDelete(EnvHelper.serviceUrl + endpoint);
}

export function sendDeleteCoSellActionListMetadata(endpoint) {
	return handleDelete(EnvHelper.coSellActionListUrl + endpoint);
}

export function sendDeleteCoSellWorkflow(endpoint) {
	return handleDelete(EnvHelper.coSellWorkflowUrl + endpoint);
}

export function sendDeleteAoiKeyGenerator(endpoint) {
	return handleDelete(EnvHelper.apiKeyGeneratorUrl + endpoint);
}

export function sendDeleteNote(endpoint) {
	return handleDelete(EnvHelper.notesUrl + endpoint);
}

export function handleDelete(endpoint) {
	if (EnvHelper.isSpoofing && !allowSpoof(endpoint)) {
		console.warn('Spoofing user! DELETE updates are blocked', endpoint);
		return Promise.resolve();
	}
	return fetch(endpoint, {method: 'DELETE', headers: headers()})
	.then((response) => {
		if (response.ok) {
			return response.text();
		}
		else {
			return handleErrorResponse(response);
		}
	});
}

export function setAuthToken(jwt) {
	if (jwt) {
		PersistenceHelper.setValue(LOGIN_TOKEN_KEY, jwt);
		EnvHelper.token = jwt;
	}
}

function setAuthHeader(headers) {
	setAuthToken(headers.get('Authorization'));
}

function handleErrorResponse(response) {
	return response.text()
	.then((text) => {
		if (response.status === 401) {
			executeLogout();
			PersistenceHelper.removeValue(LAST_ROUTE_KEY);
			PersistenceHelper.removeValue(DEEP_LINK_KEY);
		}
		else {
			throw new Error(extractMessageFromError(text));
		}
	});
}

function executeLogout() {
	EnvHelper.dispatch(AuthActions.logout());
	EnvHelper.push(Routes.LOGOUT.EXPIRED.ROUTE_TRUE);
}

function extractMessageFromError(errorString) {
	try {
		let errorJson = JSON.parse(errorString);
		// new model for server error handling
		if (errorJson.httpStatus) {
			return errorString;
		}
		// old model for server error handling
		if (errorJson.status === 404) {
			return 'Endpoint not found: ' + errorJson.path;
		}
		return errorJson.message || errorString;
	}
	catch (error) {
		return errorString;
	}
}

export function appendParams(endpoint, paramArray) {
	if (paramArray.length === 0) {
		return endpoint;
	}
	return endpoint + '?' + paramArray.join('&');
}

export function appendPageableParams(endpoint, page, pageSize, search, sort, totalElements) {
	let firstDelimiter = endpoint.indexOf('?') < 0 ? '?' : '&';
	let params = [];
	if (page || page === 0) {
		params.push('page=' + page);
	}
	if (pageSize) {
		params.push('size=' + pageSize);
	}
	if (search) {
		params.push('search=' + search);
	}
	if (sort) {
		params.push('sort=' + sort);
	}
	if (totalElements) {
		params.push('totalElements=' + totalElements);
	}
	return endpoint + firstDelimiter + params.join('&');
}

function getCanceledByBrowser(error) {
	return error.message.toLowerCase().indexOf('failed to fetch') !== -1;
}

function versionReportRequestEndpoint(endpoint) {
	const startRoute = endpoint.split('?')[0];

	if (!startRoute) {
		throw new Error(`${endpoint} is not a valid endpoint`);
	}

	const endpointsToVersion = {
		'/channelecosystem/columns': '/v1',
		'/channelecosystem/records': '/v1',
		'/channelecosystem/filterdata': '/v1',
		'/channelecosystem/report-names': '/v1',
		'/channelecosystem/dashboard': '/v1',
		'/channelecosystem/download': '/v1'
	};

	if (!endpointsToVersion[startRoute]) {
		return endpoint;
	}

	return endpointsToVersion[startRoute] + endpoint;

}