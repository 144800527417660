import * as ApiHelper from '../helpers/ApiHelper';

// LIVE
const endpointVersion = '/v1';
const endpointNotesBase = endpointVersion + '/notes';

// Notes
export function getAssetNotes(params) {
	let payload = {
		filters: params.filters || {}
	};

	let endpoint = endpointNotesBase + '/records' + '?page=' + params.page + '&size=' + params.pageSize;
	return ApiHelper.sendPostNote(endpoint, payload);
}

export function createAssetNote(payload) {
	return ApiHelper.sendPostNote(endpointNotesBase, payload);
}

export function deleteAssetNote(notePublicId) {
	return ApiHelper.sendDeleteNote(endpointNotesBase + '/' + notePublicId);
}