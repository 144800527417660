import {Warning} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as ChannelMappingEndpoints from '../../endpoints/ChannelMappingEndpoints';
import {partnerTapAlert, partnerTapWarn} from '../../styles/partnertap_theme';
import Dialog from '../Dialog';
import TextInputBox from '../TextInputBox';
import PopoverSearchList from '../PopoverSearchList';
import Loading from '../../ui/Loading';
import EnvHelper from '../../helpers/EnvHelper';
import * as PartnerOrgEndpoints from '../../endpoints/PartnerOrgEndpoints';
import StringHelper from '../../helpers/StringHelper';

export const NO_PARTNER_ORG = 'no_partner_org';
export const NEW_PARTNER_ORG = 'new_partner_org';

class PartnerReassignBase extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: false,
			orgList: [],
			selectedOrg: null,
			uploadError: null,
			newPartnerOrgName: null,
			newPartnerOrgWebsite: null,
			showNewPartnerOrgInput: false,
			isNewPartnerOrgNameMissing: false,
			isNewPartnerOrgWebsiteMissing: false,
			showVisibleOnlyToMeNote: false,
			reassignmentInProgress: false,
			isWebsiteInvalid: false
		};
		this.onEdit = this.onEdit.bind(this);
	}

	componentDidMount() {
		this.getPartnerOrgs();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getPartnerOrgs(partnerOrgPublicId) {
		this.setState({loading: true});
		const promise = partnerOrgPublicId
			? ChannelMappingEndpoints.getChannelPartnerOrgs(partnerOrgPublicId)
			: ChannelMappingEndpoints.getChannelPartnerOrgs();

		promise.then((result) => {
			if (this.unmounted) return;
			let {payload} = result;
			payload.unshift({orgCode: NO_PARTNER_ORG, name: 'VISIBLE ONLY TO ME'});
			payload.unshift({orgCode: NEW_PARTNER_ORG, name: 'NEW PARTNER ORG'});
			this.setState({orgList: payload, loading: false});
			if (this.props.onPartnerOrgsLoaded) {
				this.props.onPartnerOrgsLoaded();
			}
		});
	}

	onEdit() {
		let {selectedOrg, newPartnerOrgName, newPartnerOrgWebsite} = this.state;

		if (!selectedOrg) {
			this.setState({uploadError: 'Please select a Partner Org'});
			return;
		}

		const isInValidWebsite = newPartnerOrgWebsite && !StringHelper.validateWebsite(newPartnerOrgWebsite);

		if (selectedOrg.orgCode === NEW_PARTNER_ORG && (!newPartnerOrgName || !newPartnerOrgWebsite || isInValidWebsite)) {
			this.setState({
				isNewPartnerOrgNameMissing: !newPartnerOrgName,
				isNewPartnerOrgWebsiteMissing: !newPartnerOrgWebsite,
				isWebsiteInvalid: isInValidWebsite
			});
			return;
		}

		this.setState({reassignmentInProgress: true, isWebsiteInvalid: isInValidWebsite});

		if (newPartnerOrgName && newPartnerOrgWebsite) {
			let companyPartnerPayload = {
				partnerName: newPartnerOrgName,
				partnerWebsite: newPartnerOrgWebsite
			};

			PartnerOrgEndpoints.createPartnerOrg(companyPartnerPayload)
			.then((result) => {
				const newCompanyPartnerPublicId = result.payload.companyPartnerPublicId;
				this.handlePartnerOrgCreated(newCompanyPartnerPublicId, selectedOrg.orgCode);
			})
			.catch((error) => {
				EnvHelper.serverError('Error from onUpload', error);
				this.setState({
					reassignmentInProgress: false,
					uploadError: 'Error occurred while adding new Company Partner'
				});
			});
		}
		else {
			let cpPublicId = null, selectedOrgCode = null;
			if (selectedOrg.orgCode !== NO_PARTNER_ORG) {
				cpPublicId = selectedOrg.companyPartnerPublicId;
				selectedOrgCode = selectedOrg.orgCode;
			}
			this.handleExistingPartnerOrg(cpPublicId, selectedOrgCode);
		}
	}

	// These methods need to be implemented by child classes
	handlePartnerOrgCreated(companyPartnerPublicId, orgCode) {
		throw new Error('handlePartnerOrgCreated must be implemented by subclass');
	}

	handleExistingPartnerOrg(companyPartnerPublicId, orgCode) {
		throw new Error('handleExistingPartnerOrg must be implemented by subclass');
	}

	getErrorTextForWebsite() {
		let {isNewPartnerOrgWebsiteMissing, isWebsiteInvalid} = this.state;
		return isNewPartnerOrgWebsiteMissing ? 'Partner Website is required' :
			(isWebsiteInvalid ? 'Partner Website is Invalid' : '');
	}

	renderDialogContent() {
		let {newPartnerOrgName, loading} = this.state;

		if (loading) {
			return <Loading>Loading Partner Orgs...</Loading>;
		}

		return (
			<div>
				{this.state.reassignmentInProgress &&
				 <div style={{
					 position: 'absolute',
					 marginLeft: 'auto',
					 marginRight: 'auto',
					 left: 0,
					 right: 0,
					 backgroundColor: 'rgba(255, 255, 255, 0.8)',
					 borderRadius: 20
				 }}>
					 <Loading>Reassignment is in progress</Loading>
				 </div>}

				<div style={{display: 'flex', flexDirection: 'column', minWidth: 700, maxWidth: 700}}>
					{this.state.uploadError &&
					 <div style={{fontSize: 16, color: partnerTapAlert, textAlign: 'center'}}>
						 {this.state.uploadError}
					 </div>}

					{this.renderCustomDialogContent()}

					<div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
						<div style={{width: '35%'}}>New Org Assignment</div>
						<PopoverSearchList label={'Select Partner Org'}
										   list={this.state.orgList}
										   preselectedItem={this.state.selectedOrg}
										   labelRenderer={(org) => org.name}
										   valueRenderer={(org) => org.orgCode}
										   onItemSelected={(org) => {
											   this.setState({
												   selectedOrg: org,
												   showNewPartnerOrgInput: org.orgCode === NEW_PARTNER_ORG,
												   showVisibleOnlyToMeNote: org.orgCode === NO_PARTNER_ORG,
												   uploadError: null,
												   newPartnerOrgName: null
											   });
										   }}
										   searchByObjectKeys={['name']}
										   width={400}/>
					</div>

					{this.state.showVisibleOnlyToMeNote &&
					 <div style={{display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 5, width: 700}}>
						 <Warning style={{color: partnerTapWarn}}/>
						 VISIBLE ONLY TO ME accounts will not be accessible to others, nor included in Ecosystem Reports or managed package
						 syncs.
					 </div>}

					{this.state.showNewPartnerOrgInput &&
					 <>
						 <div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
							 <div style={{width: '35%'}}>Partner Org Name</div>
							 <TextInputBox hintText={'Partner Org Name'}
										   errorText={this.state.isNewPartnerOrgNameMissing ? 'Partner Org Name is required' : ''}
										   onChange={(value) => this.setState({
											   newPartnerOrgName: value,
											   uploadError: null,
											   isNewPartnerOrgNameMissing: false
										   })}
										   maxWidth={400}
										   maxChars={40 + (newPartnerOrgName ? newPartnerOrgName.length - newPartnerOrgName.trim().length : 0)}/>
						 </div>
						 <div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
							 <div style={{width: '35%'}}>Partner Website</div>
							 <TextInputBox hintText={'Partner Website'}
										   errorText={this.getErrorTextForWebsite()}
										   maxChars={2048}
										   onChange={(value) => this.setState({
											   newPartnerOrgWebsite: value,
											   isNewPartnerOrgWebsiteMissing: false
										   })}
										   disabled={EnvHelper.isSpoofing}/>
						 </div>
					 </>
					}
				</div>
			</div>
		);
	}

	// This method should be overridden by child classes to render custom content
	renderCustomDialogContent() {
		return null;
	}

	render() {
		let {onClose} = this.props;

		return (
			<Dialog title={'Reassign Partner Sheet Org'}
					message={this.renderDialogContent()}
					yesLabel={'REASSIGN'}
					yesAction={this.onEdit}
					noLabel={'CANCEL'}
					noAction={() => onClose(false)}/>
		);
	}
}

PartnerReassignBase.propTypes = {
	onClose: PropTypes.func.isRequired,
	onPartnerOrgsLoaded: PropTypes.func
};

export default PartnerReassignBase;